export const checkEnvironmentAndRedirect = () => {
  const liveDomain = "istanbulkart.istanbul";
  const pilotDomain = "pilot.istanbulkart.istanbul";

  const redirectToTest = "https://kisisellestirme-istanbul-website.web.app/";
  const redirectToLive = "https://kisisellestirme.istanbulkart.istanbul/";

  if (
    window.location.hostname === liveDomain ||
    window.location.hostname === pilotDomain
  ) {
    return redirectToLive;
  } else {
    return redirectToTest;
  }
};

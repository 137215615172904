import { Calender, Time } from "Icons";
import { routePath } from "Navigator/routes";
import { FunctionComponent } from "react";
import { Link } from "react-router-dom";
import "./BlogBox.scss";

type BlogBoxProps = {
  title?: string | any;
  date?: string | any;
  image?: string | any;
  desc?: string | any;
  categoryName?: string | any;
  time?: string | null | any;
  Id?: any;
};

const BlogBox: FunctionComponent<BlogBoxProps> = ({
  title,
  date = "",
  image,
  desc,
  categoryName,
  time = "",
  Id,
}) => {
  if (title == 'Sümeyraaaa') {
    console.log(image)
  }
  return (
    <Link to={`/blog-detail/${Id}`} state={{ id: Id }} className="blog__box">
      <div className="blog__box__image">
        <img src={image} alt={title} />
      </div>
      <div className="blog__box__content">
        <div className="blog__box__content__top">
          <div className="blog__box__content__top__text">
            <h3>{title}</h3>
            <div className="blog__box__content__top__text__date">
              <Calender fill="#888A97" />
              <span>{date}</span>
              <span>|</span>
              <Time fill="#888A97" />
              <span>{time + " dk"}</span>
            </div>
          </div>
          <div className="blog__box__content__top__category">
            <span>{categoryName?.Value}</span>
          </div>
        </div>
        <p
          dangerouslySetInnerHTML={{
            __html:
              desc.substring(desc.indexOf("<p>") + 3, desc.indexOf("</p>")) ??
              "",
          }}
        />
      </div>
    </Link>
  );
};

export default BlogBox;

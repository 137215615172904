import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { GetCmsModel } from "Models";
import { RootState } from "../index";
import { GlobalState } from "./types";
import languageId from "Utils/LanguageId";
import { localStorageKeys } from "Config";

const cmsKeys = localStorage.getItem("CmsKeys");
export const initialState: GlobalState = {
  loading: true,
  cmsData: cmsKeys
    ? JSON.parse(localStorage.getItem("CmsKeys") || "{}")
    : undefined,
  languageId: parseInt(languageId()) ?? 2,
  BaseCmsData: cmsKeys
    ? JSON.parse(localStorage.getItem("CmsKeys") || "{}")
    : undefined,
};

export const globalSlice = createSlice({
  name: "global",
  initialState,
  reducers: {
    onInit: (state) => {},
    apiGWTokenRequest: (state: GlobalState) => {
      state.loading = true;
    },
    cmsRequest: (state: GlobalState) => {
      state.loading = true;
    },
    cmsRequestSuccess: (state: GlobalState, action: PayloadAction<any>) => {
      state.cmsData = action.payload.CmsResult;
      state.BaseCmsData = action.payload.CMSDefaultResult;
      state.loading = false;
    },
    setLanguageId: (state: GlobalState, action: PayloadAction<number>) => {
      state.languageId = action.payload;
      localStorage.setItem(
        localStorageKeys.i18nextLng,
        action.payload == 1 ? "en" : "tr"
      );
    },
  },
});

export const {
  onInit,
  apiGWTokenRequest,
  cmsRequest,
  cmsRequestSuccess,
  setLanguageId,
} = globalSlice.actions;

export const globalStore = (state: RootState) => state.global;
export const storeCmsData = (state: RootState) => state.global.cmsData;
export const contactCmsData = (state: RootState) =>
  state.global.BaseCmsData.CmsKeys;

export default globalSlice.reducer;

// @ts-nocheck
import { getCheckParams } from "Config";
import { axiosHelper } from "../AxiosHelper";

const command = "ksl.ApproveKVKKAgreementDemo";

const approveKVKKAgreement = async ({
  kvkkAgreementVersion,
  cardNumber,
  userSMSCode,
  birthday,
  token,
  registerToken,
  commercialMessageStatus,
}: getCheckParams): Promise<ApproveKVKKAgreementDemoModel.request> => {
  return (
    await axiosHelper({
      method: "post",
      gatewayData: {
        token,
        command,
      },
      data: {
        kvkkAgreementVersion,
        cardNumber,
        userSMSCode,
        birthday,
        Token: registerToken,
        commercialMessageStatus,
      },
    })
  ).data;
};

export default approveKVKKAgreement;

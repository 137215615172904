import { checkCustomerInfromationStore } from "./checkCustomerInformationDemo/slices";
import createSagaMiddleware from "redux-saga";
import { configureStore } from "@reduxjs/toolkit";
import rootReducer from "./reducers";
import rootSaga from "./sagas";
import { createBrowserHistory } from "history";
import { RouterState, routerMiddleware } from "connected-react-router";
import { GlobalState } from "./global/types";
import { FaqsState } from "./getFaqs/types";
import { NewsState } from "./getNews/types";
import { CampaignsState } from "./getCampaigns/types";
import { AnnouncementsState } from "./getAnnouncements/types";
import { SlidersState } from "./getSliders/types";
import { CustomerInformationState } from "./checkCustomerInformationDemo/types";
import { CurriedGetDefaultMiddleware } from "@reduxjs/toolkit/dist/getDefaultMiddleware";
import { BlogsState } from "./getBlog";
import { CardsState } from "./getCards";
import { PriceLimitState } from "./GetPriceLimits";
import { ApplicationcenterState } from "./GetApplicationcenter";
import { IstanbulkartFindContentState } from "./getIstanbulkartFindContent";
import { CheckCustomerInformationDemoModel } from "Models";
import { legalTextState } from "./getIstanbulkartLegalTextPage";

export const history = createBrowserHistory();

const sagaMiddleware = createSagaMiddleware();

const middleware = (getDefaultMiddleware: CurriedGetDefaultMiddleware) =>
  getDefaultMiddleware({
    thunk: false,
    immutableCheck: false,
    serializableCheck: false,
  })
    .concat(sagaMiddleware)
    .concat(routerMiddleware(history));

const store = configureStore({
  reducer: rootReducer(history),
  middleware,
  devTools: process.env.NODE_ENV !== "production",
});

sagaMiddleware.run(rootSaga);

export interface RootState {
  global: GlobalState;
  faqs: FaqsState;
  faqsDetail: any;
  router: RouterState;
  news: NewsState;
  campaigns: CampaignsState;
  announcements: AnnouncementsState;
  sliders: SlidersState;
  customerInformation: CustomerInformationState;
  checkCustomerInfromationStore: CheckCustomerInformationDemoModel;
  blogs: BlogsState;
  cards: CardsState;
  priceLimits: PriceLimitState;
  applicationCenter: ApplicationcenterState;
  istanbulkartFindContent: IstanbulkartFindContentState;
  getIstanbulkartLegalTextPage: legalTextState;
}

export default store;

import { GetCampaignsModel } from "Models";
import { call, put, takeLatest, select } from "redux-saga/effects";
import { getCampaigns } from "Services/Queries";
import { campaignsRequest, campaignsRequestSuccess } from "./index";
import { globalStore } from "Store/global";

function* handleCampaignsRequest() {
  try {
    const { languageId } = yield select(globalStore);
    const getCampaignsResult: GetCampaignsModel = yield call(() =>
      getCampaigns({ languageId: languageId })
    );
    yield put(campaignsRequestSuccess(getCampaignsResult.Campaigns));
  } catch (error) {}
}

function* campaignSaga() {
  yield takeLatest(campaignsRequest, handleCampaignsRequest);
}

export default campaignSaga;

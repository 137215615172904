import { FunctionComponent } from "react";

const RightArrow: FunctionComponent = (
  props: React.SVGProps<SVGSVGElement>
) => {
  return (
    <svg
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_498_13800"
        maskUnits="userSpaceOnUse"
        x="12"
        y="7"
        width="12"
        height="22"
      >
        <path
          d="M21.4393 7.93934C22.0251 7.35355 22.9749 7.35355 23.5607 7.93934C24.1014 8.48006 24.143 9.33091 23.6854 9.91935L23.5607 10.0607L15.6225 18L23.5607 25.9393C24.1014 26.4801 24.143 27.3309 23.6854 27.9193L23.5607 28.0607C23.0199 28.6014 22.1691 28.643 21.5807 28.1854L21.4393 28.0607L12.4393 19.0607C11.8986 18.5199 11.857 17.6691 12.3146 17.0807L12.4393 16.9393L21.4393 7.93934Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_498_13800)">
        <rect width="36" height="36" fill="#1581BE" />
      </g>
    </svg>
  );
};

export default RightArrow;

import { FunctionComponent, useState } from "react";
import "./Tooltip.scss";
import { Typography } from "@material-ui/core";
import classNames from "classnames";
import { TooltipArrow, CloseWhiteIcon } from "Icons";
import { useWindowDimensions, useLocalization } from "Hooks";

type TabProps = {
  onClose?: Function;
  tooltipText?: string;
};
const Tooltip: FunctionComponent<TabProps> = ({
  onClose = () => {},
  tooltipText,
}) => {
  const { height, width } = useWindowDimensions();
  const localization = useLocalization();
  const makeLink = (str: string) => {
    if (str && str.includes("{%")) {
      let mySubString = str.substring(
        str.lastIndexOf("{%") + 2,
        str.lastIndexOf("%}")
      );

      let deneme = str
        .replace("{%", `<a href=${mySubString} target="_blank">`)
        .replace("%}", "</a>");
      return deneme;
    } else {
      return str;
    }
  };
  return (
    <div className="tooltip">
      <div className="tooltip__box">
        <div
          className="applicationItem__centerItem__popover__close"
          onClick={() => {
            onClose();
          }}
        >
          <CloseWhiteIcon width={16} height={16} />
        </div>

        <h6 className="applicationItem__centerItem__popover__text">
          {tooltipText}
        </h6>
        <a
          href="https://maps.google.com"
          target="_blank"
          className="applicationItem__centerItem__popover__a"
          dangerouslySetInnerHTML={{
            __html: makeLink(localization?.get_directions),
          }}
        ></a>
      </div>
      <div
        style={{
          position: "absolute",
          top: "84%",
          left: width < 600 ? 22 : 110,
        }}
      >
        <TooltipArrow />
      </div>
    </div>
  );
};

export default Tooltip;

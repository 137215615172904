import { call, put, select, takeLatest } from "redux-saga/effects";
import { cardsRequest, cardsRequestSuccess } from "./slices";
import { GetCardsModel } from "Models";
import { getCards } from "Services/Queries";
import { globalStore } from "Store/global";

function* handleCardsRequest() {
  try {
    const { languageId } = yield select(globalStore);
    let getCardsResult: GetCardsModel = yield call(() =>
      getCards({ languageId: languageId })
    );

    const cardOrderMap: { [key: string]: number } = getCardsResult.Cards.reduce(
      (acc: { [key: string]: number }, card, index) => {
        acc[card.Value] = index;
        return acc;
      },
      {}
    );

    getCardsResult.CardTypeList.sort((a, b) => {
      return cardOrderMap[a.CardMainType] - cardOrderMap[b.CardMainType];
    });

    yield put(cardsRequestSuccess(getCardsResult));
  } catch (error) {
    // Hata yönetimi yapılabilir, örneğin bir hata aksiyonu tetiklenebilir
  }
}

function* cardsSaga() {
  yield takeLatest(cardsRequest, handleCardsRequest);
}

export default cardsSaga;

import * as React from "react";

function XGrayIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_2242_297)">
        <path
          d="M12 0.579956C5.37321 0.579956 0 5.95317 0 12.58C0 19.2067 5.37321 24.58 12 24.58C18.6268 24.58 24 19.2067 24 12.58C24 5.95317 18.6268 0.579956 12 0.579956Z"
          fill="#888A97"
        />
        <g clip-path="url(#clip1_2242_297)">
          <mask
            id="mask0_2242_297"
            maskUnits="userSpaceOnUse"
            x="5"
            y="5"
            width="14"
            height="15"
          >
            <path d="M5 5.57996H19V19.58H5V5.57996Z" fill="white" />
          </mask>
          <g mask="url(#mask0_2242_297)">
            <path
              d="M16.025 6.23596H18.172L13.482 11.6066L19 18.916H14.68L11.294 14.4838L7.424 18.916H5.275L10.291 13.1696L5 6.23696H9.43L12.486 10.2874L16.025 6.23596ZM15.27 17.6288H16.46L8.78 7.45619H7.504L15.27 17.6288Z"
              fill="white"
            />
          </g>
        </g>
      </g>
      <defs>
        <clipPath id="clip0_2242_297">
          <rect
            width="24"
            height="24"
            fill="white"
            transform="translate(0 0.579956)"
          />
        </clipPath>
        <clipPath id="clip1_2242_297">
          <rect
            width="14"
            height="14"
            fill="white"
            transform="translate(5 5.57996)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

export default XGrayIcon;

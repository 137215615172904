// @ts-nocheck
import { axiosHelper } from "../AxiosHelper";
import { LegalTextPageContentList } from "Models/GetIstanbulkartLegalTextPageModel";

const url = `${process.env.REACT_APP_UMBRACO_URL}/Security/GetIstanbulkartLegalTextPage`;
const getIstanbulkartLegalTextPageService = async (
  languageId
): Promise<LegalTextPageContentList> => {
  return (
    await axiosHelper({
      method: "get",
      parameters: { languageId },
      url,
    })
  ).data;
};

export default getIstanbulkartLegalTextPageService;

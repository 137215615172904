// @ts-nocheck
import { getPriceLimitParams } from "Config";
import { axiosHelper } from "../AxiosHelper";
import { GetPriceLimitModel } from "../../Models";

const url = `${process.env.REACT_APP_UMBRACO_URL}/PriceLimit/GetPriceLimit`;
const getPriceLimit = async ({
  languageId,
}: getPriceLimitParams): Promise<GetPriceLimitModel> => {
  return (
    await axiosHelper({
      method: "get",
      url,
      data: { LanguageId: languageId },
      parameters: { languageId },
    })
  ).data;
};

export default getPriceLimit;

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { GetCardsModel } from "Models";
import { RootState } from "../index";
import { CardsState } from "./types";

export const initialState: CardsState = {
  loading: true,
  CardList: [],
  CardTypeList: [],
};

export const cardsSlice = createSlice({
  name: "cards",
  initialState,
  reducers: {
    onInit: (state) => {},
    cardsRequest: (state: CardsState) => {
      state.loading = true;
    },
    cardsRequestSuccess: (
      state: CardsState,
      action: PayloadAction<GetCardsModel>
    ) => {
      state.CardList = action.payload.CardList;
      state.CardTypeList = action.payload.CardTypeList;
      state.loading = false;
    },
  },
});

export const { onInit, cardsRequest, cardsRequestSuccess } =
  cardsSlice.actions;

export const CardsStore = (state: RootState) => state.cards;

export default cardsSlice.reducer;

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { GetFaqsModel } from "Models";
import { RootState } from "../index";
import { FaqsState } from "./types";

export const initialState: any = {};

export const faqsDetailSlice = createSlice({
  name: "faqsDetail",
  initialState,
  reducers: {
    onInit: (state) => {},
    faqsDetailRequest: (state: FaqsState, action: PayloadAction<string>) => {
      state.loading = true;
    },
    faqsDetailRequestSuccess: (state: any, action: any) => {
      state.faqDetailData = action.payload.Faq;
      state.loading = false;
    },
  },
});

export const { onInit, faqsDetailRequest, faqsDetailRequestSuccess } =
  faqsDetailSlice.actions;

export const faqsStore = (state: RootState) => state.faqs;
export const storeFaqsData = (state: RootState) => state.faqsDetail;

export default faqsDetailSlice.reducer;

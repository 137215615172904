import { GetApplicationcenterModel } from "Models";
import { call, put, takeLatest, select } from "redux-saga/effects";
import { getApplicationcenter } from "Services/Queries";
import {
  applicationcenterRequest,
  applicationcenterRequestSuccess,
} from "./index";
import { globalStore } from "Store/global";

function* handleApplicationcenterRequest() {
  try {
    const { languageId } = yield select(globalStore);
    //@ts-ignore
    const getApplicationcenterResult: GetApplicationcenterModel = yield call(
      () => getApplicationcenter({ languageId: languageId })
    );
    yield put(applicationcenterRequestSuccess(getApplicationcenterResult));
  } catch (error) {}
}

function* applicationcenterSaga() {
  yield takeLatest(applicationcenterRequest, handleApplicationcenterRequest);
}

export default applicationcenterSaga;

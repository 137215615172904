import * as React from "react";

function InstagramIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_2247_1933)">
        <path
          d="M30.625 0H9.375C4.19733 0 0 4.19733 0 9.375V30.625C0 35.8027 4.19733 40 9.375 40H30.625C35.8027 40 40 35.8027 40 30.625V9.375C40 4.19733 35.8027 0 30.625 0Z"
          fill="url(#paint0_radial_2247_1933)"
        />
        <path
          d="M30.625 0H9.375C4.19733 0 0 4.19733 0 9.375V30.625C0 35.8027 4.19733 40 9.375 40H30.625C35.8027 40 40 35.8027 40 30.625V9.375C40 4.19733 35.8027 0 30.625 0Z"
          fill="url(#paint1_radial_2247_1933)"
        />
        <path
          d="M15.1 8.33333H24.9C28.6333 8.33333 31.6667 11.3667 31.6667 15.1V24.9C31.6667 26.6946 30.9538 28.4158 29.6848 29.6848C28.4158 30.9537 26.6946 31.6667 24.9 31.6667H15.1C11.3667 31.6667 8.33334 28.6333 8.33334 24.9V15.1C8.33334 13.3054 9.04626 11.5842 10.3153 10.3152C11.5843 9.04624 13.3054 8.33333 15.1 8.33333ZM14.8667 10.6667C13.7528 10.6667 12.6845 11.1092 11.8968 11.8968C11.1092 12.6845 10.6667 13.7528 10.6667 14.8667V25.1333C10.6667 27.455 12.545 29.3333 14.8667 29.3333H25.1333C26.2473 29.3333 27.3155 28.8908 28.1032 28.1032C28.8908 27.3155 29.3333 26.2472 29.3333 25.1333V14.8667C29.3333 12.545 27.455 10.6667 25.1333 10.6667H14.8667ZM26.125 12.4167C26.5118 12.4167 26.8827 12.5703 27.1562 12.8438C27.4297 13.1173 27.5833 13.4882 27.5833 13.875C27.5833 14.2618 27.4297 14.6327 27.1562 14.9062C26.8827 15.1797 26.5118 15.3333 26.125 15.3333C25.7382 15.3333 25.3673 15.1797 25.0938 14.9062C24.8203 14.6327 24.6667 14.2618 24.6667 13.875C24.6667 13.4882 24.8203 13.1173 25.0938 12.8438C25.3673 12.5703 25.7382 12.4167 26.125 12.4167ZM20 14.1667C21.5471 14.1667 23.0308 14.7812 24.1248 15.8752C25.2188 16.9692 25.8333 18.4529 25.8333 20C25.8333 21.5471 25.2188 23.0308 24.1248 24.1248C23.0308 25.2187 21.5471 25.8333 20 25.8333C18.4529 25.8333 16.9692 25.2187 15.8752 24.1248C14.7813 23.0308 14.1667 21.5471 14.1667 20C14.1667 18.4529 14.7813 16.9692 15.8752 15.8752C16.9692 14.7812 18.4529 14.1667 20 14.1667ZM20 16.5C19.0718 16.5 18.1815 16.8687 17.5251 17.5251C16.8688 18.1815 16.5 19.0717 16.5 20C16.5 20.9283 16.8688 21.8185 17.5251 22.4749C18.1815 23.1312 19.0718 23.5 20 23.5C20.9283 23.5 21.8185 23.1312 22.4749 22.4749C23.1313 21.8185 23.5 20.9283 23.5 20C23.5 19.0717 23.1313 18.1815 22.4749 17.5251C21.8185 16.8687 20.9283 16.5 20 16.5Z"
          fill="white"
        />
      </g>
      <defs>
        <radialGradient
          id="paint0_radial_2247_1933"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(10.625 43.0808) rotate(-90) scale(39.643 36.8711)"
        >
          <stop stop-color="#FFDD55" />
          <stop offset="0.1" stop-color="#FFDD55" />
          <stop offset="0.5" stop-color="#FF543E" />
          <stop offset="1" stop-color="#C837AB" />
        </radialGradient>
        <radialGradient
          id="paint1_radial_2247_1933"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(-6.70016 2.88141) rotate(78.681) scale(17.7206 73.045)"
        >
          <stop stop-color="#3771C8" />
          <stop offset="0.128" stop-color="#3771C8" />
          <stop offset="1" stop-color="#6600FF" stop-opacity="0" />
        </radialGradient>
        <clipPath id="clip0_2247_1933">
          <rect width="40" height="40" rx="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default InstagramIcon;

import React, { useEffect } from "react";
import "./AnnouncementPopup.scss";
import { useLocalization } from "../../Hooks";

interface ILocalization {
  announcement_header?: string;
  announcement_description?: string;
  announcement_image?: string;
  announcement_link?: string;
  announcement_time?: string;
  announcement_exit_button?: string;
}

function shouldShowPopup(localization: ILocalization) {
  return localization?.announcement_image;
}

function addProtocol(url: string) {
  const protocolRegExp = /^https?:\/\//i;
  if (!protocolRegExp.test(url)) {
    return "https://" + url;
  }
  return url;
}

export interface IAnnouncementPopupProps {
  onClose: () => void;
}

const AnnouncementPopup: React.FunctionComponent<IAnnouncementPopupProps> = ({
  onClose,
}) => {
  const localization = useLocalization() as ILocalization;
  useEffect(() => {
    if (localization?.announcement_time) {
      const timeInMilliseconds =
        parseInt(localization?.announcement_time, 10) * 1000;
      if (!isNaN(timeInMilliseconds) && timeInMilliseconds > 0) {
        const timer = setTimeout(() => {
          onClose();
        }, timeInMilliseconds);
        return () => clearTimeout(timer);
      }
    }
  }, [localization, onClose]);

  const handleClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    if (localization?.announcement_link) {
      const url = addProtocol(localization.announcement_link);
      window.open(url, "_blank");
    }
  };

  if (!shouldShowPopup(localization)) {
    return null;
  }
  return (
    <div className="announcement-popup" onClick={handleClick}>
      <img src={localization?.announcement_image} />
      <button
        className="announcement-popup__close"
        onClick={(e) => {
          e.stopPropagation();
          onClose();
        }}
      >
        {localization?.announcement_exit_button}
      </button>
    </div>
  );
};

export default AnnouncementPopup;

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { GetBlogsModel } from "Models";
import { RootState } from "../index";
import { BlogsState } from "./types";

export const initialState: BlogsState = {
  loading: true,
  BlogList: [],
  Categories: [],
};

export const blogsSlice = createSlice({
  name: "blogs",
  initialState,
  reducers: {
    onInit: (state) => {},
    blogsRequest: (state: BlogsState, { payload }: PayloadAction<any>) => {
      state.loading = true;
    },
    blogDetailRequest: (state: BlogsState, { payload }: PayloadAction<any>) => {
      state.loading = true;
    },
    blogsRequestSuccess: (
      state: BlogsState,
      action: PayloadAction<GetBlogsModel["BlogList"]>
    ) => {
      state.BlogList = action.payload;
      state.loading = false;
    },
    blogDetailRequestSuccess: (
      state: BlogsState,
      action: PayloadAction<GetBlogsModel["BlogList"]>
    ) => {
      state.BlogDetail = action.payload[0];
      state.loading = false;
    },
    setCategories: (state: BlogsState, action: PayloadAction<any>) => {
      state.Categories = action.payload.categories;
    },
  },
});

export const {
  onInit,
  blogsRequest,
  blogDetailRequest,
  blogsRequestSuccess,
  blogDetailRequestSuccess,
  setCategories,
} = blogsSlice.actions;

export const blogsStore = (state: RootState) => state.blogs;
export const storeBlogsData = (state: RootState) => state.blogs.BlogList;

export default blogsSlice.reducer;

import { FunctionComponent } from "react";

const FaceBook: FunctionComponent = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_822_1061)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4 16.067C4 22.033 8.333 26.994 14 28V19.333H11V16H14V13.333C14 10.333 15.933 8.667 18.667 8.667C19.533 8.667 20.467 8.8 21.333 8.933V12H19.8C18.333 12 18 12.733 18 13.667V16H21.2L20.667 19.333H18V28C23.667 26.994 28 22.034 28 16.067C28 9.43 22.6 4 16 4C9.4 4 4 9.43 4 16.067Z"
          fill="#1581BE"
        />
      </g>
      <defs>
        <clipPath id="clip0_822_1061">
          <rect
            width="24"
            height="24"
            fill="white"
            transform="translate(4 4)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default FaceBook;

import React, { FunctionComponent } from "react";
import Modal from "react-modal";
import { Box, Typography } from "@material-ui/core";
import { useLocalization } from "Hooks";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../Store";

const customStyles = {
  content: {
    display: "fixed",
    top: "60%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    borderRadius: 23,
    width: "70%",
    height: "400px",
    backgroundColor: "white",
    paddingTop: "16px",
    paddingBottom: "16px",
    zIndex: 999,
  },
};
type ModalCompProps = {
  modalIsOpen?: any;
  setIsOpen?: any;
  title?: string;
  id?: number;
  itemID?: string;
  searchQuery?: string;
  selectedCategory?: Function;
};
Modal.setAppElement(document.getElementById("root")!);

const ModalComp: FunctionComponent<ModalCompProps> = ({
  modalIsOpen,
  setIsOpen,
  searchQuery,
  selectedCategory = () => {},
}) => {
  const localization = useLocalization();
  const { FaqCategory } = useSelector((state: RootState) => state.faqs);
  function closeModal() {
    setIsOpen(false);
  }
  return (
    <div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <Typography variant="h6" align="center">
          <Box fontWeight={700} className="faqs__curiosities__title">
            {localization?.faq_left_menu_title}
          </Box>
        </Typography>
        {FaqCategory?.map((item: any, i: number) => {
          return (
            <>
              <div
                style={{
                  height: 2,
                  backgroundColor: "#F5F5F6",
                  marginTop: 13,
                  marginBottom: 13,
                }}
              />
              <Typography variant="h6" align="left">
                <Box
                  fontWeight={700}
                  className="faqs__curiosities__title"
                  onClick={() => {
                    closeModal();
                    selectedCategory(item?.ParentName ?? "");
                  }}
                >
                  {item?.ParentName}
                </Box>
              </Typography>
              {item?.Childs &&
                item?.Childs.map((child: any, j: any) => {
                  return (
                    <Typography variant="h6" align="left">
                      <Box
                        fontWeight={700}
                        className="faqs__curiosities__title"
                        style={{margin:5}}
                        onClick={() => {
                          closeModal();
                          selectedCategory(child?.ChildName ?? "");
                        }}
                      >
                        {child?.ChildName}
                      </Box>
                    </Typography>
                  );
                })}
            </>
          );
        })}
      </Modal>
    </div>
  );
};
export default ModalComp;

// @ts-nocheck
import GetSecurityModel, { Security } from "Models/GetSecurity";
import { axiosHelper } from "../AxiosHelper";

const url = `${process.env.REACT_APP_UMBRACO_URL}/Security/GetIstanbulkartSecurityPage?languageId=2"`;
const getSecurity = async (): Promise<Security> => {
  return (
    await axiosHelper({
      method: "get",
      url,
    })
  ).data;
};

export default getSecurity;

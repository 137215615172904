// @ts-nocheck
import { getFaqsParams } from "Config";
import { axiosHelper } from "../AxiosHelper";
import { GetAnnouncementsModel } from "./../../Models";

const url = `${process.env.REACT_APP_UMBRACO_URL}/Announcement/GetAnnouncements`;
const getAnnouncements = async ({
  languageId,
}: getFaqsParams): Promise<GetAnnouncementsModel> => {
  return (
    await axiosHelper({
      method: "get",
      url,
      parameters: { LanguageId: languageId },
    })
  ).data;
};

export default getAnnouncements;

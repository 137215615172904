import { FunctionComponent } from "react";

const HeaderMenu: FunctionComponent<React.SVGProps<SVGSVGElement>> = ({
                                                                          width,
                                                                          height,
                                                                          onClick,
                                                                      }: any) => {
    return (
        <div onClick={onClick ?? (() => {})} style={{ cursor: 'pointer' }}>
            <svg
                width={width ?? "24"}
                height={height ?? "24"}
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M3.95 6C3.42533 6 3 6.42533 3 6.95V7.05C3 7.57467 3.42533 8 3.95 8H20.05C20.5747 8 21 7.57467 21 7.05V6.95C21 6.42533 20.5747 6 20.05 6H3.95ZM3 11.95C3 11.4253 3.42533 11 3.95 11H20.05C20.5747 11 21 11.4253 21 11.95V12.05C21 12.5747 20.5747 13 20.05 13H3.95C3.42533 13 3 12.5747 3 12.05V11.95ZM3 16.95C3 16.4253 3.42533 16 3.95 16H20.05C20.5747 16 21 16.4253 21 16.95V17.05C21 17.5747 20.5747 18 20.05 18H3.95C3.42533 18 3 17.5747 3 17.05V16.95Z"
                    fill="black"
                />
                <mask
                    id="mask0_1132_4506"
                    maskUnits="userSpaceOnUse"
                    x="3"
                    y="6"
                    width="18"
                    height="12"
                >
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M3.95 6C3.42533 6 3 6.42533 3 6.95V7.05C3 7.57467 3.42533 8 3.95 8H20.05C20.5747 8 21 7.57467 21 7.05V6.95C21 6.42533 20.5747 6 20.05 6H3.95ZM3 11.95C3 11.4253 3.42533 11 3.95 11H20.05C20.5747 11 21 11.4253 21 11.95V12.05C21 12.5747 20.5747 13 20.05 13H3.95C3.42533 13 3 12.5747 3 12.05V11.95ZM3 16.95C3 16.4253 3.42533 16 3.95 16H20.05C20.5747 16 21 16.4253 21 16.95V17.05C21 17.5747 20.5747 18 20.05 18H3.95C3.42533 18 3 17.5747 3 17.05V16.95Z"
                        fill="white"
                    />
                </mask>
                <g mask="url(#mask0_1132_4506)">
                    <rect
                        x="-1.33334"
                        y="-1.33337"
                        width="26.6667"
                        height="26.6667"
                        fill="#67DBFF"
                    />
                </g>
            </svg>
        </div>
    );
};

export default HeaderMenu;

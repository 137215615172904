import { FunctionComponent } from "react";

const Home: FunctionComponent = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_822_740"
        maskUnits="userSpaceOnUse"
        x="2"
        y="2"
        width="19"
        height="20"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.7099 2.30001L20.4199 10.18C20.7927 10.5666 21.0007 11.0829 20.9999 11.62V20C21.0015 21.063 20.1713 21.9415 19.1099 22H15.9999V13C15.9999 12.4477 15.5522 12 14.9999 12H8.99989C8.4476 12 7.99989 12.4477 7.99989 13V22H4.88989C3.82849 21.9415 2.99828 21.063 2.99989 20V11.62C2.99414 11.0849 3.20304 10.5699 3.57989 10.19L11.2899 2.30001C11.4777 2.1107 11.7332 2.00421 11.9999 2.00421C12.2665 2.00421 12.5221 2.1107 12.7099 2.30001ZM13.9999 14H9.99989V21H13.9999V14Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_822_740)">
        <rect width="24" height="24" fill="#1AAADC" />
      </g>
    </svg>
  );
};

export default Home;

export enum CardTypes {
  INDIRIMLI_KART = "İndirimli İstanbulkart",
  INDIRIMLI_KART_ENG = "Discounted Travel İstanbulkart",

  ISTANBULKART = "İstanbulkart",
  MAVI_KART = "Mavi İstanbulkart",
  MAVI_KART_ENG = "Monthly Travel İstanbulkart",
  UCRETSIZ_KART = "Ücretsiz İstanbulkart",
  UCRETSIZ_KART_ENG = "Free Travel İstanbulkart",

  SINIRLI_KULLANIMLI_KART = "Sınırlı Kullanımlı Bilet",
  ISTANBUL_CITY_KART = "Istanbul City Card",
  ISTANBULKART_PLUS = "İstanbulkart Plus",
  ISTANBULKARTENG = "IstanbulCard",
}

export enum SearchCategory {
  NEWS = "News",
  WEB_SLIDER = "WebSlider",
  FAQ = "FAQ",
  BLOG = "Blog",
  CARD = "Card",
  APPLICATIONCENTER = "ApplicationCenter",
}

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { GetPriceLimitModel } from "Models";
import { RootState } from "../index";
import { PriceLimitState } from "./types";

export const initialState: PriceLimitState = {
  loading: true,
  PriceLimitList: {
    LimitTitleOrderList: [],
    FeeTables: [
      {
        CardTypeName: "",
        CardFeeValue: "",
        VisaIssuance: "",
      },
    ],
    UsegaFees: [
      {
        CardTypeName: "",
        TransitionFee: "",
        SubscriptionFee: "",
      },
    ],
    IrregularUseFees: [
      {
        CardTypeName: "",
        CardFeeValue: "",
      },
    ],
    BalanceRefundProcedures: [
      {
        RefundedAmount: "",
        ServiceFee: "",
      },
    ],
    LoadBalanceProcessFees: [
      {
        RefundedAmount: "",
        ServiceFee: "",
      },
    ],
    BankCardTransactionFees: [
      {
        ProcessName: "",
        ProcessFee: "",
      },
    ],
    LimitTables: [
      {
        ProcessName: "",
        ApprovedContract: "",
        UnapprovedContract: "",
        Visaization: "",
        Type: {
          Id: 0,
          Value: "",
        },
      },
    ],
    CityCardFeeTables: [
      {
        TariffName: "",
        TariffFee: "",
        TariffAdditionalField: "",
      },
    ],
  },
  LimitTitleOrderList: [],
};

export const priceLimitSlice = createSlice({
  name: "priceLimits",
  initialState,
  reducers: {
    onInit: (state) => {},
    priceLimitRequest: (state: PriceLimitState) => {
      state.loading = true;
    },
    priceLimitRequestSuccess: (
      state: PriceLimitState,
      action: PayloadAction<GetPriceLimitModel>
    ) => {
      state.PriceLimitList = action.payload.PriceLimitList;
      state.LimitTitleOrderList = action.payload.LimitTitleOrderList;
      state.loading = false;
    },
  },
});

export const { onInit, priceLimitRequest, priceLimitRequestSuccess } =
  priceLimitSlice.actions;

export const PriceLimitsStore = (state: RootState) => state.priceLimits;

export default priceLimitSlice.reducer;

import { FunctionComponent } from "react";

const TooltipArrow: FunctionComponent<React.SVGProps<SVGSVGElement>> = (
  props
) => {
  const randomId =
    Math.floor(Math.random() * 1000) + "_" + Math.floor(Math.random() * 100);
  return (
    <svg
      width="29"
      height="29"
      viewBox="0 0 29 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="14.1421"
        width="20"
        height="20"
        transform="rotate(45 14.1421 0)"
        fill="#606375"
      />
    </svg>
  );
};

export default TooltipArrow;

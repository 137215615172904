import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  CheckCustomerInformationDemoModel,
  ApproveKVKKAgreementDemoModel,
} from "Models";
import { RootState } from "../index";
import {
  CustomerInformationState,
  ApproveKVKKAgreementState,
  OptCheck,
} from "./types";
import OptModel from "Models/OptModel";

export const initialState: CustomerInformationState = {
  loading: true,
  approveKVKKAgreement: undefined,
  CheckCustomerInformation: undefined,
};

export const customerInformationSlice = createSlice({
  name: "customerInformation",
  initialState,
  reducers: {
    onInit: (state) => {},
    checkCustomerInformationRequest: (
      state: CustomerInformationState,
      action: { payload: any }
    ) => {
      state.loading = true;
    },
    checkCustomerInformationRequestSuccess: (
      state: CustomerInformationState,
      action: PayloadAction<CheckCustomerInformationDemoModel>
    ) => {
      state.CheckCustomerInformation = action.payload;
      state.loading = false;
    },
    approveKVKKAgreementRequest: (
      state: ApproveKVKKAgreementState,
      action: { payload: any }
    ) => {
      state.loading = true;
    },
    approveKVKKAgreementSuccess: (
      state: ApproveKVKKAgreementState | any,
      action: PayloadAction<ApproveKVKKAgreementDemoModel>
    ) => {
      state.approveKVKKAgreement = action.payload;
      state.loading = false;
    },
    optCheck: (state: OptCheck, action: PayloadAction<OptModel>) => {
      //state.Question = action.payload;
      state.loading = false;
    },
  },
});

export const {
  onInit,
  checkCustomerInformationRequest,
  checkCustomerInformationRequestSuccess,
  approveKVKKAgreementRequest,
  approveKVKKAgreementSuccess,
  optCheck,
} = customerInformationSlice.actions;

export const checkCustomerInfromationStore = (state: RootState) =>
  state.customerInformation;
//export const storeCheckCustomerInfromation = (state: RootState) => state.customerInformation.Question;

export default customerInformationSlice.reducer;

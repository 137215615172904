import { PayloadAction } from "@reduxjs/toolkit";
import { GetBlogsModel } from "Models";
import { getBlogs } from "Services/Queries";
import { call, put, takeLatest } from "redux-saga/effects";
import {
  blogDetailRequest,
  blogDetailRequestSuccess,
  blogsRequest,
  blogsRequestSuccess,
} from "./index";

function* handleBlogsRequest({ payload }: PayloadAction<any>) {
  try {
    const getBlogsResult: GetBlogsModel = yield call(() =>
      getBlogs({ payload })
    );

    yield put(blogsRequestSuccess(getBlogsResult.BlogList));
  } catch (error) {}
}

function* handleBlogDetailRequest({ payload }: PayloadAction<any>) {
  try {
    const getBlogsResult: GetBlogsModel = yield call(() =>
      getBlogs({ payload })
    );

    yield put(blogDetailRequestSuccess(getBlogsResult.BlogList));
  } catch (error) {}
}

function* blogsSaga() {
  yield takeLatest(blogsRequest, handleBlogsRequest);
  yield takeLatest(blogDetailRequest, handleBlogDetailRequest);
}

export default blogsSaga;

import { GetPriceLimitModel } from "Models";
import { call, put, takeLatest, select } from "redux-saga/effects";
import { getPriceLimit } from "Services/Queries";
import { priceLimitRequest, priceLimitRequestSuccess } from "./index";
import { globalStore } from "Store/global";

function* handlePriceLimitRequest() {
  try {
    const { languageId } = yield select(globalStore);
    //@ts-ignore
    const getPriceLimitResult: GetPriceLimitModel = yield call(() =>
      getPriceLimit({ languageId: languageId })
    );
    yield put(priceLimitRequestSuccess(getPriceLimitResult));
  } catch (error) {}
}

function* priceLimitSaga() {
  yield takeLatest(priceLimitRequest, handlePriceLimitRequest);
}

export default priceLimitSaga;

import {
  CheckCustomerInformationDemoModel,
  ApproveKVKKAgreementDemoModel,
} from "Models";
import { call, put, takeLatest } from "redux-saga/effects";
import {
  approveKVKKAgreement,
  checkCustomerInformation,
} from "Services/Queries";
import {
  checkCustomerInformationRequest,
  checkCustomerInformationRequestSuccess,
  approveKVKKAgreementRequest,
  approveKVKKAgreementSuccess,
} from "./index";

function* handleCheckCustomerInformation({ payload }: { payload: any }) {
  const { getResponse, ...requestPayload } = payload; // Destructuring kullanarak payload'dan getResponse'ü ayırdık.

  try {
    const response: CheckCustomerInformationDemoModel = yield call(
      checkCustomerInformation,
      requestPayload
    );
    getResponse(response);
    yield put(checkCustomerInformationRequestSuccess(response));
  } catch (error) {
    console.error("Error while checking customer information:", error);
  }
}

function* handleOptCheck({ payload }: { payload: any }) {
  const { getResponse, ...requestPayload } = payload; // Destructuring kullanarak payload'dan getResponse'ü ayırdık.

  try {
    const response: CheckCustomerInformationDemoModel = yield call(
      checkCustomerInformation,
      requestPayload
    );
    getResponse(response);
    yield put(checkCustomerInformationRequestSuccess(response));
  } catch (error) {
    console.error("Error while checking customer information:", error);
  }
}

function* handleApproveKVKKAgreement({ payload }: { payload: any }) {
  try {
    const data = {
      kvkkAgreementVersion: 0,
      cardNumber: payload?.kartNum?.replaceAll(" ", ""),
      userSMSCode: payload.sms,
      birthday: payload?.dogumTarihi,
      token: payload?.token,
      registerToken: payload?.registerToken,
      commercialMessageStatus: !!payload?.agreement4,
    };
    const response: ApproveKVKKAgreementDemoModel = yield call(
      approveKVKKAgreement,
      data
    );
    yield put(approveKVKKAgreementSuccess(response));
  } catch (error) {
    // console.log("Error while approving KVKK agreement:", error);
  }
}

function* checkCustomerInfromationSaga() {
  yield takeLatest(
    checkCustomerInformationRequest,
    handleCheckCustomerInformation
  );
  yield takeLatest(approveKVKKAgreementRequest, handleApproveKVKKAgreement);
}

export default checkCustomerInfromationSaga;

import { GetFaqsModel } from "Models";
import { call, put, takeLatest, select } from "redux-saga/effects";
import { getFaqs } from "Services/Queries";
import { faqsRequest, faqsRequestSuccess } from "./index";
import { globalStore } from "Store/global";
import { PayloadAction } from "@reduxjs/toolkit";

function* handleFaqsRequest({ payload }: PayloadAction<any>) {
  try {
    const { languageId } = yield select(globalStore);
    const getFaqsResult: GetFaqsModel = yield call(() =>
      getFaqs({ languageId: languageId, Filter: payload })
    );
    yield put(faqsRequestSuccess(getFaqsResult));
  } catch (error) {}
}

function* faqSaga() {
  yield takeLatest(faqsRequest, handleFaqsRequest);
}

export default faqSaga;

import { FunctionComponent, useState, memo, useEffect } from "react";
import {
  IstanbulKartLogo,
  Home,
  Search,
  HeaderMenu,
  CloseWhiteIcon,
  IstanbulKartWhite,
  Close,
} from "Icons";
import { useNavigate, Link, useLocation } from "react-router-dom";
import styles from "./Header.module.scss";
import { Box, Divider, Drawer, Link as MUILink } from "@material-ui/core";
import { useWindowDimensions, useLocalization } from "Hooks";
import { Input } from "Components";
import { useSpring, animated } from "react-spring";
import { routePath } from "Navigator/routes";
import { useDispatch, useSelector } from "react-redux";
import { cmsRequest, setLanguageId } from "Store/global";
import { faqsRequest } from "Store/getFaqs";
import { newsRequest } from "Store/getNews";
import { campaignsRequest } from "Store/getCampaigns";
import { announcementsRequest } from "Store/getAnnouncements";
import { slidersRequest } from "Store/getSliders";
import { blogsRequest } from "Store/getBlog";
import { globalStore } from "Store/global";
import { cardsRequest } from "Store/getCards";
import { priceLimitRequest } from "Store/GetPriceLimits";
import { applicationcenterRequest } from "Store/GetApplicationcenter";
import { istanbulkartFindContentRequest } from "Store/getIstanbulkartFindContent";
import { getIstanbulkartLegalTextPage } from "Store/getIstanbulkartLegalTextPage";
import { SecurityRequest } from "Store/getSecurity";

export type WelcomeHeaderMenuItemType = {
  title: string;
  onClick: () => void;
  Icon?: any;
  itemID: string;
};
const Header: FunctionComponent = ({}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const localization = useLocalization();
  const { languageId } = useSelector(globalStore);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isVisibleSearch, isSetVisibleSearch] = useState(false);
  const [searchValue, setSearchValue] = useState<string>("");
  const [activeTab, setActiveTab] = useState("");
  const { height } = useWindowDimensions();

  const menuItems = [
    {
      title: localization?.menu_item_cards,
      onClick: () => {
        setIsOpen(false);
        navigate(routePath?.ourCards);
      },
      itemID: "languageBtnTest",
    },
    {
      title: localization?.menu_item_mobile,
      onClick: () => {
        setIsOpen(false);
        navigate(routePath?.istanbulkartMobile);
      },
      itemID: "footerReactUsBtnTest",
    },
    {
      title: localization?.menu_item_campaigns,
      onClick: () => {
        setIsOpen(false);
        navigate(routePath?.campaigns);
      },
      itemID: "footerFaqBtnTest",
    },
    {
      title: localization?.menu_button_online_transactions,
      onClick: () => {
        setIsOpen(false);
        navigate(routePath?.onlineTransactions);
      },
      itemID: "loginBtnTest",
    },
  ];

  const handleDirectHomePage = () => {};
  const [moveInput, setMoveInput] = useState(true);

  const props = useSpring({
    transform: moveInput ? "translateX(1vh)" : "translateX(50vh)",
  });

  const filterMethod = () => {
    dispatch(
      istanbulkartFindContentRequest({
        languageId: languageId,
        SearchKey: searchValue,
      })
    );
    navigate(routePath.searchListPage);
  };

  useEffect(() => {
    if (location.pathname === routePath.ourCards) {
      setActiveTab(localization?.menu_item_cards);
    } else if (location.pathname === routePath.istanbulkartMobile) {
      setActiveTab(localization?.menu_item_mobile);
    } else if (location.pathname === routePath.campaigns) {
      setActiveTab(localization?.menu_item_campaigns);
    } else {
      setActiveTab("");
    }
  }, [navigate, localization]);
  const zoom = ((window.outerWidth - 10) / window.innerWidth) * 100;
  return (
    <div
      style={{ marginBottom: zoom < 50 ? 100 : 0 }}
      className="pageContainer"
    >
      <div className={styles.header}>
        <div className={styles.header__content}>
          <div>
            <div className={styles.header__headerRegular}>
              <div
                className={styles.header__logo}
                onClick={() => {
                  navigate("/");
                }}
              >
                <IstanbulKartLogo />
              </div>
              <div className={styles.header__bar}>
                <HeaderMenu
                  width={36}
                  height={36}
                  onClick={() => {
                    setIsOpen(true);
                  }}
                />
                {isOpen && (
                  <Drawer
                    open={isOpen}
                    onClose={() => {
                      setIsOpen(false);
                    }}
                    classes={{ paper: styles.header__welcomeDrawer }}
                    PaperProps={{
                      style: {
                        height: height,
                      },
                    }}
                  >
                    <Box
                      padding={"10px"}
                      justifyContent="center"
                      display="flex"
                      className={styles.header__welcomeDrawer__logo}
                    >
                      <div onClick={handleDirectHomePage}>
                        <IstanbulKartWhite
                        // width={125}
                        />
                      </div>
                      <div
                        className={styles.header__welcomeDrawer__close}
                        onClick={() => {
                          setIsOpen(false);
                        }}
                      >
                        <CloseWhiteIcon fill={"white"} />
                      </div>
                    </Box>

                    <Box
                      paddingLeft={"10px"}
                      paddingRight={"10px"}
                      display="flex"
                      flexDirection="column"
                      height="100%"
                    >
                      <div className={styles.header__welcomeDrawer__divider}>
                        <Divider />
                      </div>

                      <Box display="flex" flexDirection="column-reverse">
                        {menuItems.map(({ title, onClick, itemID }, index) => (
                          <MenuItem
                            key={index}
                            title={title}
                            onClick={onClick}
                            //   Icon={Icon && Icon}
                            itemID={itemID}
                          />
                        ))}
                      </Box>
                    </Box>
                  </Drawer>
                )}
              </div>
            </div>
            <div className={styles.nav__content}>
              <div className={styles.buttons}>
                <div
                  className={styles.buttons__home}
                  onClick={() => {
                    navigate("/");
                  }}
                >
                  <Home />
                </div>
                <Link
                  className={styles.buttons__text}
                  style={{
                    color:
                      activeTab === localization?.menu_item_cards
                        ? "#00D5FF"
                        : "#383c52",
                  }}
                  to={routePath?.ourCards}
                >
                  {localization?.menu_item_cards ?? ""}
                </Link>
                {!!localization?.menu_item_mobile && (
                  <Link
                    className={styles.buttons__text}
                    style={{
                      color:
                        activeTab === localization?.menu_item_mobile
                          ? "#00D5FF"
                          : "#383c52",
                    }}
                    to={routePath?.istanbulkartMobile}
                  >
                    {localization?.menu_item_mobile ?? ""}
                  </Link>
                )}

                <Link
                  className={styles.buttons__text}
                  style={{
                    color:
                      activeTab === localization?.menu_item_campaigns
                        ? "#00D5FF"
                        : "#383c52",
                  }}
                  to={routePath.campaigns}
                >
                  {localization?.menu_item_campaigns ?? ""}
                </Link>
              </div>
            </div>
          </div>
          <div className={styles.header__right_menu}>
            {isVisibleSearch && (
              <animated.div
                className={styles.header__right_menu__search__animated}
                style={props}
              >
                <div className={styles.header__right_menu__searchInput}>
                  <Input
                    labelText={localization?.header_search_button_desc}
                    typeInput="text"
                    value={searchValue}
                    onChangeText={(val: string) => {
                      setSearchValue(val);
                      if (val.length >= 3) {
                        filterMethod();
                      }
                    }}
                    width={200}
                    height={52}
                    RightIcon={<Close fill="#C3C4CB" width={20} height={20} />}
                    onPressRightIcon={() => {
                      isSetVisibleSearch(!isVisibleSearch);
                      setMoveInput(!moveInput);
                      setSearchValue("");
                      dispatch(
                        istanbulkartFindContentRequest({
                          languageId: languageId,
                          SearchKey: "",
                        })
                      );
                    }}
                  />
                </div>
              </animated.div>
            )}
            {!isVisibleSearch && (
              <div
                className={styles.header__right_menu__search}
                onClick={() => {
                  isSetVisibleSearch(!isVisibleSearch);
                  setMoveInput(!moveInput);
                }}
              >
                <Search id={"mask0_726_375header"} />
              </div>
            )}

            <div
              style={{
                zIndex: 999,
              }}
            >
              {!!localization?.menu_button_online_transactions && (
                <button
                  className={styles.header__toggler}
                  onClick={() => {
                    navigate("/onlineTransactions");
                  }}
                >
                  {localization?.menu_button_online_transactions ??
                    "Online İşlemler"}
                </button>
              )}

              <button
                className={styles.header__language}
                onClick={() => {
                  const id = languageId === 2 ? 1 : 2;
                  dispatch(setLanguageId(id));
                  dispatch(cmsRequest());
                  dispatch(priceLimitRequest());
                  dispatch(campaignsRequest());
                  dispatch(announcementsRequest());
                  dispatch(faqsRequest(""));
                  dispatch(cardsRequest());
                  dispatch(newsRequest());
                  dispatch(slidersRequest());
                  dispatch(applicationcenterRequest());
                  dispatch(getIstanbulkartLegalTextPage());
                  dispatch(SecurityRequest(""));
                  dispatch(
                    blogsRequest({ LanguageId: languageId, CategoryName: "" })
                  );
                }}
              >
                {languageId === 1 ? "TR" : "EN"}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const MenuItem: FunctionComponent<WelcomeHeaderMenuItemType> = memo(
  ({ title, onClick, Icon, itemID }: WelcomeHeaderMenuItemType) => (
    <>
      <Divider />
      <Box display="flex" paddingBottom={"10px"} paddingTop={"10px"}>
        {Icon && (
          <Box marginRight={"10px"} style={{ marginTop: "-2px" }}>
            <Icon />
          </Box>
        )}
        <MUILink
          underline="none"
          // display="block"
          onClick={onClick}
          itemID={itemID}
          style={{
            fontFamily: "Mark Pro",
            fontWeight: 700,
            fontSize: 18,
            letterSpacing: -0.5,
            color: "#FFFFFF",
            marginLeft: 30,
            marginTop: 18,
            marginBottom: 18,
            cursor: "pointer",
          }}
        >
          <Box fontWeight="bold">{title}</Box>
        </MUILink>
      </Box>
    </>
  )
);
export default Header;

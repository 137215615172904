// @ts-nocheck
import { getBlogsParams } from "Config";
import { axiosHelper } from "../AxiosHelper";
import { GetNewsModel } from "./../../Models";

const url = `${process.env.REACT_APP_UMBRACO_URL}/Blog/Blogs`;
const getBlogs = async ({ payload }: getBlogsParams): Promise<GetNewsModel> => {
  return (
    await axiosHelper({
      method: "post",
      url,
      data: payload,
    })
  ).data;
};

export default getBlogs;

import ReactGA from "react-ga4";
import { useLocation, useNavigate, useParams } from "react-router-dom";

export function withRouter<ComponentProps>(
  Component: React.FunctionComponent<ComponentProps>
) {
  function ComponentWithRouterProp(props: ComponentProps) {
    const location = useLocation();
    const navigate = useNavigate();
    const params = useParams();

    return <Component {...props} router={{ location, navigate, params }} />;
  }

  return ComponentWithRouterProp;
}

const RouteChangeTracker = ({ router }: any) => {
    const location = router?.location;
    ReactGA.set({ page: location?.pathname });
    ReactGA.send(location?.pathname);
  return <div></div>;
};

export default withRouter(RouteChangeTracker);

import { useLocalization } from "Hooks";
import { routePath } from "Navigator/routes";
import { FunctionComponent } from "react";
import { isMobile } from "react-device-detect";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Images from "../../Assets/Images";
import { FaceBook, Instagram, Linkedin, Tiktok, X, Youtube } from "../../Icons";
import { globalStore } from "../../Store/global";
import "./Footer.scss";

const Footer: FunctionComponent = ({}) => {
  const localization = useLocalization();
  const { languageId } = useSelector(globalStore);
  const call153 = () => {
    window.open("tel:153");
  };

  const call4442161 = () => {
    window.open("tel:4442161");
  };

  const showVersion = process.env.REACT_APP_SHOW_VERSION === "true";

  return (
    <footer id="footer">
      <div className="footer__line" />
      <div className="footer pageContainer">
        <p className="footer__left">
          <div className="footer__row1 ">
            {/* <img src={Images.TcmbBelbim} alt="TCMBBELBIM" width={"100%"} /> */}
            <img src={Images.IbbFooter} alt="IBB" width={160} />
            <img
              width={160}
              className="link"
              src={Images.Belbim}
              onClick={() => {
                let newWindow: any = window.open();
                if (process.env.NODE_ENV === "test") {
                  newWindow.location =
                    "https://tstcms.belbim.istanbul/Umbraco/Api";
                } else {
                  newWindow.location = "https://www.belbim.istanbul/";
                }
              }}
            />
          </div>
          {/* <div className="footer__row">
             <img src={Images.IbbFooter2} alt="IBB" width={"100%"} />
            
          </div> */}
          <div className="footer__row">
            <div>
              <img className="tcmb__img" width="100%" src={Images.Tcmb} />
            </div>
            <div>
              <img width="100%" src={Images.BKMLogo} />
            </div>
          </div>
          <div className="footer__detail footer__detail__l__mobile">
            <p className="footer__text">{localization?.footer_official_name}</p>
          </div>
          <div className="footer__detail footer__detail__l__mobile">
            <p className="footer__text">{localization?.footer_rights}</p>
          </div>
          {showVersion && (
            <p className="version__number">
              Versiyon {process.env.REACT_APP_VERSION}
            </p>
          )}
        </p>
        <div className="footer__right">
          <div className="footer__right__menuRow">
            <div className="footer__right__menu">
              {!!localization?.footer_news && (
                <Link className="footer__right__link" to={routePath.news}>
                  {localization?.footer_news}
                </Link>
              )}
              {!!localization?.footer_fees_and_limits && (
                <Link
                  className="footer__right__link"
                  to={routePath.priceAndLimit}
                >
                  {localization?.footer_fees_and_limits}
                </Link>
              )}
              {!!localization?.footer_faq && (
                <Link className="footer__right__link" to={routePath.faqs}>
                  {localization?.footer_faq}
                </Link>
              )}
              {!!localization?.footer_announcements && (
                <Link
                  className="footer__right__link"
                  to={routePath.announcements}
                >
                  {localization?.footer_announcements}
                </Link>
              )}
              {!!localization?.footer_legal_texts && (
                <Link className="footer__right__link" to={routePath.legalText}>
                  {localization?.footer_legal_texts}
                </Link>
              )}
              {!!localization?.footer_app_centers && (
                <Link
                  className="footer__right__link"
                  to={routePath.applicationCenters}
                >
                  {localization?.footer_app_centers}
                </Link>
              )}
              <div
                style={{ marginTop: languageId === 1 ? 75 : -5 }}
                className="footer__right__link__bireysel"
              >
                <h5 className="footer__right__link">
                  {localization?.footer_ind_cust_line}
                </h5>
                <div
                  onClick={() => {
                    isMobile ? call153() : undefined;
                  }}
                >
                  <a href="tel:153" className="footer__right__text2">
                    {localization?.footer_alo_153}
                  </a>
                </div>
              </div>
            </div>
            <div className="footer__right__menu">
              {!!localization?.footer_blog && (
                <Link className="footer__right__link" to={routePath.blog}>
                  {localization?.footer_blog}
                </Link>
              )}
              {!!localization?.footer_security && (
                <Link className="footer__right__link" to={routePath.security}>
                  {localization?.footer_security}
                </Link>
              )}
              {!!localization?.footer_press_materials && (
                <Link
                  className="footer__right__link"
                  to={routePath.mediaMaterials}
                >
                  {localization?.footer_press_materials}
                </Link>
              )}
              {!!localization?.footer_contact && (
                <Link className="footer__right__link" to={routePath.contact}>
                  {localization?.footer_contact}
                </Link>
              )}
              <div className="footer__right__topHeight" />

              <div
                style={{ marginTop: 25 }}
                className="footer__right__link__bireyselSmall"
              >
                <h5 className="footer__right__link">
                  {localization?.footer_ind_cust_line}
                </h5>
                <div
                  onClick={() => {
                    isMobile ? call153() : undefined;
                  }}
                >
                  <a href="tel:153" className="footer__right__text2">
                    {localization?.footer_alo_153}
                  </a>
                </div>
              </div>
              <div>
                <h5 className="footer__right__link">
                  {localization?.footer_corp_cust_line}
                </h5>
                <div
                  onClick={() => {
                    isMobile ? call4442161() : undefined;
                  }}
                >
                  <a href="tel:4442161" className="footer__right__text2">
                    {localization?.footer_corp_tel}
                  </a>
                </div>
              </div>
            </div>
            <div className="footer__right__menu footer__right__menu__rightMargin">
              <h5 className="footer__right__link">
                {localization?.footer_mobile_apps}
              </h5>
              <div className="footer__right__height" />
              <div
                className="footer__right__clickable"
                onClick={() => {
                  let newWindow: any = window.open();
                  newWindow.location = "https://app.adjust.com/1bu8dnlh ";
                }}
              >
                <img
                  src={Images.AppDowland1}
                  alt="IBB"
                  width={135}
                  height={40}
                />
              </div>
              <div
                className="footer__right__clickable"
                onClick={() => {
                  let newWindow: any = window.open();
                  newWindow.location = "https://app.adjust.com/1bg6xa6x";
                }}
              >
                <img
                  src={Images.AppDowland2}
                  alt="IBB"
                  width={135}
                  height={40}
                />
              </div>
              <div
                className="footer__right__clickable"
                onClick={() => {
                  let newWindow: any = window.open();
                  newWindow.location =
                    "https://appgallery.huawei.com/app/C102347171?sharePrepath=ag&locale=tr_TR&source=appshare&subsource=C102347171";
                }}
              >
                <img
                  src={Images.AppDowland3}
                  alt="IBB"
                  width={135}
                  height={40}
                />
              </div>
              <div style={{ marginTop: 40 }}>
                <h5 className="footer__right__link">
                  {localization?.footer_social_media}
                </h5>
                <div className="footer__right__container">
                  <div
                    className="footer__right__clickable"
                    onClick={() => {
                      let newWindow: any = window.open();
                      newWindow.location =
                        "https://www.instagram.com/istanbulkart.istanbul/";
                    }}
                  >
                    <Instagram />
                  </div>
                  <div
                    className="footer__right__clickable"
                    onClick={() => {
                      let newWindow: any = window.open();
                      newWindow.location =
                        "https://www.facebook.com/istanbulkart/";
                    }}
                  >
                    <FaceBook />
                  </div>
                  <div
                    className="footer__right__clickable"
                    onClick={() => {
                      let newWindow: any = window.open();
                      newWindow.location =
                        "https://twitter.com/istanbulkart?ref_src=twsrc%5Egoogle%7Ctwcamp%5Eserp%7Ctwgr%5Eauthor";
                    }}
                  >
                    <X />
                  </div>
                  <div
                    className="footer__right__clickable"
                    onClick={() => {
                      let newWindow: any = window.open();
                      newWindow.location =
                        "https://www.tiktok.com/@istanbulkart.istanbul";
                    }}
                  >
                    <Tiktok />
                  </div>
                  {/* <div
                    className="footer__right__clickable"
                    onClick={() => {
                      let newWindow: any = window.open();
                      newWindow.location = "https://www.youtube.com/@Belbim";
                    }}
                  >
                    <Youtube />
                  </div> */}
                </div>
                {/* <div className="footer__right__container">
                 
                  {/* <div
                    className="footer__right__clickable"
                    onClick={() => {
                      let newWindow: any = window.open();
                      newWindow.location =
                        "https://www.linkedin.com/company/belbimas";
                    }}
                  >
                    <Linkedin />
                  </div> */}
                {/* </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;

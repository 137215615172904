import * as React from "react";

function FacebookGrayIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 12.647C0 18.613 4.333 23.574 10 24.58V15.913H7V12.58H10V9.91296C10 6.91296 11.933 5.24696 14.667 5.24696C15.533 5.24696 16.467 5.37996 17.333 5.51296V8.57996H15.8C14.333 8.57996 14 9.31296 14 10.247V12.58H17.2L16.667 15.913H14V24.58C19.667 23.574 24 18.614 24 12.647C24 6.00996 18.6 0.579956 12 0.579956C5.4 0.579956 0 6.00996 0 12.647Z"
        fill="#888A97"
      />
    </svg>
  );
}

export default FacebookGrayIcon;

import * as React from "react";

function Tiktok(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1308_18335)">
        <path
          d="M16 28C9.3736 28 4 22.6264 4 16C4 9.3736 9.3736 4 16 4C22.6264 4 28 9.3736 28 16C28 22.6264 22.6264 28 16 28Z"
          fill="#1581BE"
        />
        <path
          d="M16.3943 7.03286C17.374 7.0177 18.3481 7.02668 19.321 7.0177C19.38 8.16357 19.792 9.33077 20.6308 10.1409C21.4679 10.9713 22.6519 11.3513 23.804 11.4799V14.4942C22.7244 14.4588 21.6397 14.2343 20.66 13.7694C20.2333 13.5763 19.8358 13.3276 19.4468 13.0732C19.4417 15.2605 19.4557 17.445 19.4327 19.6234C19.3743 20.6699 19.0291 21.7113 18.4205 22.5737C17.4414 24.0092 15.7419 24.9451 13.9965 24.9743C12.9258 25.0355 11.8563 24.7436 10.944 24.2057C9.43207 23.3142 8.36817 21.6821 8.21322 19.9305C8.1937 19.5595 8.1907 19.1878 8.20423 18.8166C8.33897 17.3923 9.04356 16.0297 10.1372 15.1028C11.3768 14.0232 13.1133 13.5089 14.7392 13.8132C14.7544 14.922 14.71 16.0297 14.71 17.1385C13.9673 16.8982 13.0993 16.9656 12.4503 17.4164C11.9755 17.7292 11.6166 18.1894 11.4291 18.7262C11.2741 19.1057 11.3185 19.5274 11.3274 19.9305C11.5054 21.1589 12.6866 22.1913 13.9476 22.0796C14.7836 22.0706 15.5847 21.5856 16.0204 20.8754C16.1613 20.6266 16.3191 20.3723 16.3275 20.0798C16.401 18.7408 16.3718 17.4074 16.3808 16.0684C16.387 13.0508 16.3713 10.041 16.3943 7.03286Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_1308_18335">
          <rect
            width="24"
            height="24"
            fill="white"
            transform="translate(4 4)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

export default Tiktok;

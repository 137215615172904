import { FunctionComponent } from "react";

const Info: FunctionComponent = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="38"
      height="38"
      viewBox="0 0 38 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_1_8251"
        maskUnits="userSpaceOnUse"
        x="3"
        y="3"
        width="32"
        height="32"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M19.0001 3.16667C10.2556 3.16667 3.16675 10.2555 3.16675 19C3.16675 27.7445 10.2556 34.8333 19.0001 34.8333C27.7446 34.8333 34.8334 27.7445 34.8334 19C34.8334 14.8007 33.1653 10.7735 30.1959 7.80414C27.2266 4.83482 23.1993 3.16667 19.0001 3.16667ZM20.5834 25.3333C20.5834 26.2078 19.8745 26.9167 19.0001 26.9167C18.1256 26.9167 17.4167 26.2078 17.4167 25.3333V17.4167C17.4167 16.5422 18.1256 15.8333 19.0001 15.8333C19.8745 15.8333 20.5834 16.5422 20.5834 17.4167V25.3333ZM17.4167 12.6667C17.4167 13.5411 18.1256 14.25 19.0001 14.25C19.8745 14.25 20.5834 13.5411 20.5834 12.6667C20.5834 11.7922 19.8745 11.0833 19.0001 11.0833C18.1256 11.0833 17.4167 11.7922 17.4167 12.6667Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_1_8251)">
        <rect width="38" height="38" rx="12" fill="#383C52" />
      </g>
    </svg>
  );
};

export default Info;

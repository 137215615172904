import { PayloadAction } from "@reduxjs/toolkit";
import GetSecurityModel from "Models/GetSecurity";
import getSecurity from "Services/Queries/GetSecurity";
import { call, put, takeLatest } from "redux-saga/effects";
import {
  SecurityRequest,
  SecurityRequestSuccess,
  SecurityState,
} from "./index";

function* handleSecurityRequest({ payload }: PayloadAction<any>) {
  try {
    const getSecurityResult: GetSecurityModel = yield call(() => getSecurity());
    yield put(SecurityRequestSuccess(getSecurityResult));
  } catch (error) {}
}

function* securitySaga() {
  yield takeLatest(SecurityRequest, handleSecurityRequest);
}

export default securitySaga;

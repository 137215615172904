import { GetApiGWTokenModel, GetCmsModel } from "Models";
import { call, delay, put, takeLatest, select } from "redux-saga/effects";
import { getApiGWRefreshToken, getApiGWToken, getCms } from "Services/Queries";
import {
  getApiGWRefreshTokenCookie,
  getApiGWTokenCookie,
  makeLink,
  setApiGWCookies,
} from "Utils";
import {
  apiGWTokenRequest,
  cmsRequest,
  cmsRequestSuccess,
  onInit,
} from "./index";
import { globalStore } from "Store/global";

function* appInit() {}

function* handleCmsRequest() {
  try {
    const { languageId } = yield select(globalStore);
    const userGWToken = getApiGWTokenCookie();
    if (!userGWToken) {
      yield put(apiGWTokenRequest());
      yield delay(1500);
    }

    let getCmsResult: GetCmsModel = yield call(() =>
      getCms({ languageId: languageId })
    );
    localStorage.setItem("CmsKeys", JSON.stringify(getCmsResult));
    const baseCmsResult = structuredClone(getCmsResult);
    for (let key in getCmsResult.CmsKeys) {
      if (typeof getCmsResult.CmsKeys[key] === "string") {
        getCmsResult.CmsKeys[key] = makeLink(
          getCmsResult.CmsKeys[key] as string
        );
      }
    }

    yield put(
      cmsRequestSuccess({
        CmsResult: getCmsResult,
        CMSDefaultResult: baseCmsResult,
      })
    );
  } catch (error) {}
}

function* handleOnApiGWTokenRequest() {
  const tokenCookie = getApiGWTokenCookie();
  const refreshTokenCookie = getApiGWRefreshTokenCookie();
  if (!tokenCookie) {
    if (refreshTokenCookie) {
      try {
        const getApiGwRefreshTokenResult: GetApiGWTokenModel = yield call(() =>
          getApiGWRefreshToken(refreshTokenCookie)
        );

        setApiGWCookies(getApiGwRefreshTokenResult.token);
      } catch (error) {}
    } else {
      try {
        const getApiGwTokenResult: GetApiGWTokenModel = yield call(
          getApiGWToken
        );
        const { token, refreshToken } = getApiGwTokenResult;

        setApiGWCookies(token, refreshToken);
      } catch (error) {}
    }
  }
}

function* globalSaga() {
  yield takeLatest(onInit, appInit);
  yield takeLatest(cmsRequest, handleCmsRequest);
  yield takeLatest(apiGWTokenRequest, handleOnApiGWTokenRequest);
}

export default globalSaga;

// @ts-nocheck
import { getCmsParams, pathURls } from "Config";
import { axiosHelper } from "../AxiosHelper";
import { GetCmsModel } from "./../../Models";

const url = `${process.env.REACT_APP_UMBRACO_URL}/Translations/GetTranslations`;
const getCms = async ({ languageId }: getCmsParams): Promise<GetCmsModel> => {
  return (
    await axiosHelper({
      method: "get",
      url,
      data: { LanguageId: languageId },
      parameters: { languageId, versionNumber: 0 },
    })
  ).data;
};

export default getCms;

import { RouteProps } from "react-router-dom";
import { lazy } from "react";

const Home = lazy(() => import("../Pages/Home/Home"));
const Campaigns = lazy(() => import("../Pages/Campaigns/Campaigns"));
const News = lazy(() => import("../Pages/News/News"));
const CampaignsDetail = lazy(
  () => import("../Pages/CampaignsDetail/CampaignsDetail")
);
const OurCards = lazy(() => import("../Pages/OurCards/OurCards"));
const CardDetail = lazy(() => import("../Pages/CardDetail/CardDetail"));
const ApplicationCenters = lazy(
  () => import("../Pages/ApplicationCenters/ApplicationCenters")
);
const IstanbulkartMobile = lazy(
  () => import("../Pages/IstanbulkartMobile/IstanbulkartMobile")
);
const OnlineTransactions = lazy(
  () => import("../Pages/OnlineTransactions/OnlineTransactions")
);
const LegalText = lazy(() => import("../Pages/LegalText/LegalText"));
const LegalTextDetail = lazy(
  () => import("../Pages/LegalTextDetail/LegalTextDetail")
);
const MediaMaterials = lazy(
  () => import("../Pages/MediaMaterials/MediaMaterials")
);
const Contact = lazy(() => import("../Pages/Contact/Contact"));
const Announcements = lazy(
  () => import("../Pages/Announcements/Announcements")
);
const AnnouncementsDetail = lazy(
  () => import("../Pages/AnnouncementsDetail/AnnouncementsDetail")
);
const NewsDetail = lazy(() => import("../Pages/NewsDetail/NewsDetail"));
const Security = lazy(() => import("../Pages/Security/Security"));
const Blog = lazy(() => import("../Pages/Blog/Blog"));
const BlogDetail = lazy(() => import("../Pages/BlogDetail/BlogDetail"));
const Faqs = lazy(() => import("../Pages/Faqs/Faqs"));
const FaqsDetail = lazy(() => import("../Pages/FaqsDetail/FaqsDetail"));
const PriceAndLimit = lazy(
  () => import("../Pages/PriceAndLimit/PriceAndLimit")
);
const SearchListPage = lazy(
  () => import("../Pages/SearchListPage/SearchListPage")
);

export type RoutesType = {
  isPrivate?: boolean;
  component?: any;
  exact?: any;
  title: any;
} & RouteProps;

export const routePath = {
  home: "/",
  campaigns: "/campaigns",
  campaignsDetail: "/campaignsDetail",
  news: "/news",
  newsDetail: "/news/:slug",
  ourCards: "/OurCards",
  cardDetail: "/CardDetail/:id",
  applicationCenters: "/applicationCenters",
  istanbulkartMobile: "/istanbulkartMobile",
  onlineTransactions: "/onlineTransactions",
  personalization: "/personalization",
  legalText: "/legal-text",
  legalTextDetail: "/legal-text/detail",
  mediaMaterials: "/media-materials",
  contact: "/contact",
  announcements: "/announcements",
  announcementsDetail: "/announcements/detail",
  security: "/security",
  blog: "/blog",
  blogDetail: "/blog-detail/:id",
  faqs: "/faqs",
  faqsDetail: "/faqsDetail",
  priceAndLimit: "/price-limit",
  searchListPage: "/SearchListPage",
};

export const routes: RoutesType[] = [
  {
    isPrivate: false,
    path: routePath.home,
    component: Home,
    title: "homepage_page_title",
  },
  {
    isPrivate: false,
    path: routePath.campaigns,
    component: Campaigns,
    title: "homepage_title_campaigns",
  },
  {
    isPrivate: false,
    path: routePath.news,
    component: News,
    title: "footer_news",
  },
  {
    isPrivate: false,
    path: routePath.campaignsDetail,
    component: CampaignsDetail,
    title: "homepage_title_campaigns",
  },
  {
    isPrivate: false,
    path: routePath.ourCards,
    component: OurCards,
    title: "menu_item_cards",
  },
  {
    isPrivate: false,
    path: routePath.cardDetail,
    component: CardDetail,
    title: "menu_item_cards",
  },
  {
    isPrivate: false,
    path: routePath.applicationCenters,
    component: ApplicationCenters,
    title: "footer_app_centers",
  },
  {
    isPrivate: false,
    path: routePath.istanbulkartMobile,
    component: IstanbulkartMobile,
    title: "menu_item_mobile",
  },
  {
    isPrivate: false,
    path: routePath.onlineTransactions,
    component: OnlineTransactions,
    title: "online_transactions_page_title",
  },

  {
    isPrivate: false,
    path: routePath.legalText,
    component: LegalText,
    title: "footer_legal_texts",
  },
  {
    isPrivate: false,
    path: routePath.legalTextDetail,
    component: LegalTextDetail,
    title: "footer_legal_texts",
  },
  {
    isPrivate: false,
    path: routePath.mediaMaterials,
    component: MediaMaterials,
    title: "footer_press_materials",
  },
  {
    isPrivate: false,
    path: routePath.contact,
    component: Contact,
    title: "footer_contact",
  },
  {
    isPrivate: false,
    path: routePath.announcements,
    component: Announcements,
    title: "footer_announcements",
  },
  {
    isPrivate: false,
    path: routePath.announcementsDetail,
    component: AnnouncementsDetail,
    title: "footer_announcements",
  },
  {
    isPrivate: false,
    path: routePath.newsDetail,
    component: NewsDetail,
    title: "news_page_title",
  },
  {
    isPrivate: false,
    path: routePath.security,
    component: Security,
    title: "footer_security",
  },
  {
    isPrivate: false,
    path: routePath.blog,
    component: Blog,
    title: "footer_blog",
  },
  {
    isPrivate: false,
    path: routePath.blogDetail,
    component: BlogDetail,
    title: "footer_blog",
  },
  {
    isPrivate: false,
    path: routePath.faqs,
    component: Faqs,
    title: "footer_faq",
  },
  {
    isPrivate: false,
    path: routePath.faqsDetail,
    component: FaqsDetail,
    title: "footer_faq",
  },
  {
    isPrivate: false,
    path: routePath.priceAndLimit,
    component: PriceAndLimit,
    title: "footer_fees_and_limits",
  },
  {
    isPrivate: false,
    path: routePath.searchListPage,
    component: SearchListPage,
    title: "homepage_page_title",
  },
];

import React, { useEffect, useLayoutEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useLocation, Routes, Route } from "react-router-dom";
import { globalStore } from "Store/global";
import { routes } from "./routes";
import { RouteChangeTracker } from "Components";

const RedirectToHome: React.FC = () => {
  const navigate = useNavigate();

  useEffect(() => {
    navigate("/");
  }, [navigate]);

  return null;
};

const ScrollToTop: React.FC<any> = ({ children }) => {
  const location = useLocation();

  useLayoutEffect(() => {
    document.documentElement.scrollTo(0, 0);
  }, [location.pathname]);

  return <>{children}</>;
};

const Navigator: React.FC = () => {
  const { cmsData } = useSelector(globalStore);

  return (
    <React.Suspense fallback={<div></div>}>
      <RouteChangeTracker />
      <ScrollToTop>
        <Routes>
          {routes.map((route, index) => (
            <Route
              key={`page-${index}`}
              path={route.path}
              element={
                <route.component
                  title={cmsData?.CmsKeys[route.title] || "Ana Sayfa"}
                />
              }
            />
          ))}
          <Route path="*" element={<RedirectToHome />} />
        </Routes>
      </ScrollToTop>
    </React.Suspense>
  );
};

export default Navigator;

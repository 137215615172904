import React, { FunctionComponent, useEffect, useState } from "react";
import "./Slider.scss";
import { BigRightArrow, LeftArrow } from "Icons";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination } from "swiper";
import { Button } from "Components";
import AnnouncementCard from "./AnnouncementCard";
import { ScrollMenu, VisibilityContext } from "react-horizontal-scrolling-menu";
import { useLocalization, useWindowDimensions } from "Hooks";
import { useNavigate } from "react-router-dom";
import { routePath } from "Navigator/routes";
import { useSelector } from "react-redux";
import { RootState } from "Store";
import moment from "moment";
import classNames from "classnames";
import {globalStore} from "../../Store/global";

type scrollVisibilityApiType = React.ContextType<typeof VisibilityContext>;
const Slider: FunctionComponent = ({}) => {
  const { width } = useWindowDimensions();
  const { AnnouncementList } = useSelector(
    (state: RootState) => state.announcements
  );
  const { WebSliderList } = useSelector((state: RootState) => state.sliders);
  const navigate = useNavigate();
  const localization = useLocalization();
  const [offscreenLoad, setOffscreenLoad] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setOffscreenLoad(true);
    }, 1000);
  }, []);

  function onWheel(
    apiObj: scrollVisibilityApiType,
    ev: React.WheelEvent
  ): void {
    const isThouchpad = Math.abs(ev.deltaX) !== 0 || Math.abs(ev.deltaY) < 15;

    if (isThouchpad) {
      ev.stopPropagation();
      return;
    }

    if (ev.deltaY < 0) {
      apiObj.scrollNext();
    } else if (ev.deltaY > 0) {
      apiObj.scrollPrev();
    }
  }
  function addProtocol(url: string) {
    const protocolRegExp = /^https?:\/\//i;
    if (!protocolRegExp.test(url)) {
      return "https://" + url;
    }
    return url;
  }
  const arrayToSort = AnnouncementList || [];
  const sortedAnnouncementList = arrayToSort
    .slice()
    .sort(function (a: any, b: any) {
      return new Date(a.Date).valueOf() - new Date(b.Date).valueOf();
    });
  function array_chunks(array: any, chunks: number) {
    let result: any = [];
    let n = array.length;
    for (let i = 0; i < n; i += chunks) {
      result = [...result, array.slice(i, i + chunks)];
    }
    return result;
  }
  const dummyData = sortedAnnouncementList ?? [];
  const zoom = ((window.outerWidth - 10) / window.innerWidth) * 100;
  const { languageId } = useSelector(globalStore);
  return (
    <div className="home" style={{ marginTop: zoom < 34 ? 110 : 0 }}>
      <Swiper
        pagination={true}
        modules={[Pagination]}
        className="home top-slider"
      >
        {WebSliderList &&
          WebSliderList.map((item, i) => {
            return (
              <SwiperSlide>
                <div className="home__bgColor">
                  <div
                    className={classNames({
                      home__swiper__bg: true,
                      home__swiper__bg__dnm:
                        item?.SliderHeader.length === 0 &&
                        item?.SliderDescription.length === 0
                          ? true
                          : false,
                    })}
                  >
                    {item?.SliderHeader.length > 0 &&
                      item?.SliderDescription.length > 0 && (
                        <div
                          className={classNames({
                            home__swiper__bg__left: true,
                            home__swiper__bg__leftWidth:
                              item?.SliderHeader.length === 0 &&
                              item?.SliderDescription.length === 0
                                ? false
                                : true,
                          })}
                        >
                          <h1 className="home__swiper__bg__title">
                            {item?.SliderHeader}
                          </h1>
                          <p className="home__swiper__bg__content">
                            {item?.SliderDescription}
                          </p>
                          {item?.IsVisibleButton && item?.RedirectUrl && (
                            <div className="home__swiper__bg__button">
                              <Button
                                text={item?.ButtonText}
                                onClick={() => {
                                  if (item?.RedirectUrl.includes("https://")) {
                                    let newWindow: any = window.open();
                                    newWindow.location = item?.RedirectUrl;
                                  } else {
                                    let newWindow: any = window.open();
                                    newWindow.location =
                                      "https://" + item?.RedirectUrl;
                                  }
                                }}
                                variant="contained"
                                className="home__swiper__bg__button"
                              />
                            </div>
                          )}
                        </div>
                      )}

                    <div className="home__swiper__bg__cardsTiny">
                      {item?.RedirectUrl ? (
                        <a
                          href={addProtocol(item?.RedirectUrl)}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {i === 0 ? (
                            <img
                              src={item?.SliderImageUrl}
                              alt={item?.SliderHeader}
                              // object-fit="contain"
                              width={"100%"}
                              // className={classNames({
                              //   "img-mobile-margin":
                              //     item?.SliderHeader.length === 0 &&
                              //     item?.SliderDescription.length === 0
                              //       ? true
                              //       : false,
                              // })}
                            />
                          ) : (
                            <img
                              {...(offscreenLoad
                                ? {
                                    src: item?.SliderImageUrl ?? "",
                                  }
                                : {})}
                              alt={item?.SliderHeader}
                              // object-fit="contain"
                              width={"100%"}
                              // className={classNames({
                              //   "img-mobile-margin":
                              //     item?.SliderHeader.length === 0 &&
                              //     item?.SliderDescription.length === 0
                              //       ? true
                              //       : false,
                              // })}
                            />
                          )}
                        </a>
                      ) : i === 0 ? (
                        <img
                          src={item?.SliderImageUrl}
                          alt={item?.SliderHeader}
                          // object-fit="contain"
                          width={"100%"}
                          // className="img-mobile-margin"
                        />
                      ) : (
                        <img
                          {...(offscreenLoad
                            ? {
                                src: item?.SliderImageUrl ?? "",
                              }
                            : {})}
                          alt={item?.SliderHeader}
                          // object-fit="contain"
                          width={"100%"}
                          // className="img-mobile-margin"
                        />
                      )}
                    </div>
                    {item?.SliderHeader.length === 0 &&
                    item?.SliderDescription.length === 0 ? (
                      <div className="home__swiper__bg__cards2">
                        {item?.SliderImageUrl !== null && item?.RedirectUrl ? (
                          <a
                            href={addProtocol(item?.RedirectUrl)}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {i === 0 ? (
                              <img
                                src={item?.SliderImageUrl}
                                alt={item?.SliderHeader}
                              />
                            ) : (
                              <img
                                {...(offscreenLoad
                                  ? {
                                      src: item?.SliderImageUrl ?? "",
                                    }
                                  : {})}
                                alt={item?.SliderHeader}
                              />
                            )}
                          </a>
                        ) : i === 0 ? (
                          <img src={item?.SliderImageUrl} alt={""} />
                        ) : (
                          <img
                            {...(offscreenLoad
                              ? {
                                  src: item?.SliderImageUrl ?? "",
                                }
                              : {})}
                            alt={""}
                          />
                        )}
                      </div>
                    ) : (
                      <div className="home__swiper__bg__cards">
                        {item?.SliderImageUrl !== null && item?.RedirectUrl ? (
                          <a
                            href={addProtocol(item?.RedirectUrl)}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {i === 0 ? (
                              <img
                                src={item?.SliderImageUrl}
                                alt={item?.SliderHeader}
                              />
                            ) : (
                              <img
                                {...(offscreenLoad
                                  ? {
                                      src: item?.SliderImageUrl ?? "",
                                    }
                                  : {})}
                                alt={item?.SliderHeader}
                              />
                            )}
                          </a>
                        ) : i === 0 ? (
                          <img src={item?.SliderImageUrl} alt={""} />
                        ) : (
                          <img
                            {...(offscreenLoad
                              ? {
                                  src: item?.SliderImageUrl ?? "",
                                }
                              : {})}
                            alt={""}
                          />
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </SwiperSlide>
            );
          })}
      </Swiper>
      {languageId == 1 ?  null : <div
          className={classNames({
            home__announcementCardContainer: width > 1400 ? false : true,
            pageContainer: width > 1400 ? true : false,
          })}
          style={{ paddingTop: 0 }}
      >
        <h1
            className="home__announcement"
            style={{ marginTop: 44, marginBottom: 32.02 }}
        >
          {localization?.homepage_title_announcements}
        </h1>
        <ScrollMenu
            LeftArrow={LeftArrowFunc}
            RightArrow={RightArrowFunc}
            onWheel={onWheel}
        >
          {width <= 400 ? (
              <div style={{ display: "flex", flexDirection: "column" }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  {array_chunks(dummyData, dummyData.length / 2)[0] &&
                      array_chunks(dummyData, dummyData.length / 2)[0].map(
                          (item: any, i: any) => {
                            return (
                                <AnnouncementCard
                                    id={i}
                                    title={item.Header}
                                    subtitle={
                                      item?.Date
                                          ? moment(
                                              item.Date.includes("Z")
                                                  ? item.Date
                                                  : item.Date + "Z"
                                          )
                                              .utc()
                                              .format("DD.MM.YYYY")
                                          : ""
                                    }
                                    onClick={() => {
                                      navigate({
                                        pathname: routePath.announcementsDetail,
                                        search: `?id=${item.Id}`,
                                      });
                                    }}
                                    key={item.Id}
                                    // itemID={item.itemID}
                                />
                            );
                          }
                      )}
                </div>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  {array_chunks(dummyData, dummyData.length / 2)[1] &&
                      array_chunks(dummyData, dummyData.length / 2)[1].map(
                          (item: any, i: any) => {
                            return (
                                <AnnouncementCard
                                    id={i}
                                    title={item.Header}
                                    subtitle={item?.Date ? (moment(
                                        item.Date.includes("Z")
                                            ? item.Date
                                            : item.Date + "Z"
                                    )
                                        .utc()
                                        .format("DD.MM.YYYY")) : ""}
                                    onClick={() => {
                                      navigate({
                                        pathname: routePath.announcementsDetail,
                                        search: `?id=${item.Id}`,
                                      });
                                    }}
                                    key={item.Id}
                                    // itemID={item.itemID}
                                />
                            );
                          }
                      )}
                </div>
              </div>
          ) : (
              dummyData &&
              dummyData.reverse().map((item, i) => {
                return (
                    <AnnouncementCard
                        id={i}
                        title={item.Header}
                        subtitle={item?.Date ? (moment(
                            //@ts-ignore
                            item.Date.includes("Z") ? item.Date : item.Date + "Z"
                        )
                            .utc()
                            .format("DD.MM.YYYY")) : ""}
                        onClick={() => {
                          navigate({
                            pathname: routePath.announcementsDetail,
                            search: `?id=${item.Id}`,
                          });
                        }}
                        key={item.Id}
                        // itemID={item.itemID}
                    />
                );
              })
          )}
        </ScrollMenu>
        <div className="home__buttonContainer">
          <Button
              text={localization?.homepage_main_button_announcements}
              onClick={() => {
                navigate(routePath.announcements);
              }}
              variant="outlined"
              className="home__campaigns__button"
          />
        </div>
      </div>}



    </div>
  );
};

export default Slider;

function LeftArrowFunc() {
  const { scrollPrev } = React.useContext(VisibilityContext);

  return (
    <div className="home__circle" onClick={() => scrollPrev()}>
      <LeftArrow />
    </div>
  );
}

function RightArrowFunc() {
  const { scrollNext } = React.useContext(VisibilityContext);

  return (
    <div className="home__circle" onClick={() => scrollNext()}>
      <BigRightArrow />
    </div>
  );
}

import { FunctionComponent } from "react";

const X: FunctionComponent = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_2287_579)">
        <path
          d="M16 4C9.37321 4 4 9.37321 4 16C4 22.6268 9.37321 28 16 28C22.6268 28 28 22.6268 28 16C28 9.37321 22.6268 4 16 4Z"
          fill="#1581BE"
        />
        <g clip-path="url(#clip1_2287_579)">
          <mask
            id="mask0_2287_579"
            maskUnits="userSpaceOnUse"
            x="9"
            y="9"
            width="14"
            height="14"
          >
            <path d="M9 9H23V23H9V9Z" fill="white" />
          </mask>
          <g mask="url(#mask0_2287_579)">
            <path
              d="M20.025 9.65601H22.172L17.482 15.03L23 22.344H18.68L15.294 17.909L11.424 22.344H9.275L14.291 16.594L9 9.65701H13.43L16.486 13.71L20.025 9.65601ZM19.27 21.056H20.46L12.78 10.877H11.504L19.27 21.056Z"
              fill="white"
            />
          </g>
        </g>
      </g>
      <defs>
        <clipPath id="clip0_2287_579">
          <rect
            width="24"
            height="24"
            fill="white"
            transform="translate(4 4)"
          />
        </clipPath>
        <clipPath id="clip1_2287_579">
          <rect
            width="14"
            height="14"
            fill="white"
            transform="translate(9 9)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default X;

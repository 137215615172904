const Images = {
  BKMLogo: require("./BKMLogo.png"),
  MasterBanner: require("./masterbanner.png"),
  IstanbulkartYeniGorsel: require("./IstanbulkartYeniGorsel.webp"),
  IstanbulkartEskiGorsel: require("./IstanbulkartEskiGorsel.webp"),
  Popover: require("./Popover.webp"),
  IbbLogo: require("./IbbLogo.webp"),
  IbbBelbimLogo: require("./ibb-belbim.webp"),
  BelbimStandart: require("./belbim-standart.webp"),
  IstanbulStandart: require("./istanbul-standart.webp"),
  HataliLogoKullanimi: require("./hatali-logo-kullanimi.webp"),
  MobileMockUp1: require("./mobil-mockup-1.webp"),
  MobileMockUp2: require("./mobil-mockup-2.webp"),
  MobileMockUp3: require("./mobil-mockup-3.webp"),
  MobileMockUp4: require("./mobil-mockup-4.webp"),
  Basin1: require("./basin-gorsel-1.webp"),
  Basin2: require("./basin-gorsel-2.webp"),
  Basin1Png: require("./basin-gorsel-1.png"),
  Basin2Png: require("./basin-gorsel-2.png"),
  SadEmoji: require("./SadEmoji.webp"),
  IstanbulkartMobil1: require("./banner-phone-1.png"),
  IstanbulkartMobil2: require("./bannerPhone2.png"),
  IstanbulkartMobilImg: require("./IstanbulkartMobilImg.webp"),
  IstanbulkartmobileappImg: require("./IstanbulkartmobileappImg.webp"),
  SifremiDegistir: require("./sifremidegistir.png"),
  SifremiDegistirMobil: require("./sifremidegistirmobil.png"),
  SecurityImg: require("./securityImg.png"),
  Cards: require("./newBanneer.png"),
  OurCards: require("./OurCards.webp"),
  Belbim: require("./belbim.webp"),
  Tcmb: require("./tcmb.webp"),
  TcmbBelbim: require("./TcmbBelbim.png"),
  IbbFooter: require("./IbbFooter.png"),
  IbbFooter2: require("./IbbFooter2.png"),
  IstanbulkartGeneric: require("./sinirlikart.png"),
  IstanbulkartPlus: require("./istanbulkartPlus.png"),
  IstanbulkartCityCard: require("./citycard.webp"),
  IstanbulkartYellow: require("./yellow.webp"),
  IstanbulkartBlue: require("./bluecard.webp"),
  IstanbulkartGreen: require("./greencard.webp"),
  IstanbulkartRed: require("./redcard.webp"),
  IstanbulkartLogoSiyah: require("./istanbul_kart_logo_siyah.webp"),
  IstanbulkartLogoBeyaz: require("./istanbul_kart_logo_beyaz.webp"),
  NewCardMobile: require("./new_card_mobile.webp"),
  Blog1: require("./Blog1.e0cfc04c3df0e877be6d.webp"),
  Blog2: require("./Blog2.7f53466ebedcd60fc441.webp"),
  Blog3: require("./Blog3.cacc28e3c01af0b34789.webp"),
  Blog4: require("./Blog4.ba924dd6dc21c793f050.webp"),
  IBM: require("./ibm.png"),
  CozumMerkezi: require("./cozum-merkezi.png"),
  AppDowland1: require("./AppDowland2.png"),
  AppDowland2: require("./AppDowland1.png"),
  AppDowland3: require("./AppDowland3.png"),
  NewCards: require("./NewCard.png"),
  OldCards: require("./OldCard.png"),
};

export default Images;

export type ImageType = typeof Images;

import { FunctionComponent } from "react";
//import { Select, MenuItem, FormControl, InputLabel } from "@material-ui/core";
import CreatableSelect from "react-select/creatable";
import "./SelectList.scss";

type SelectListProps = {
  selectedValue: number | string;
  data: any;
  placeholder: string;
  onChangeValue: Function;
};

const SelectList: FunctionComponent<SelectListProps> = ({
  selectedValue,
  data,
  placeholder,
  onChangeValue,
  ...props
}) => {
  const colourStyles = {
    control: (styles: any) => ({
      ...styles,
      backgroundColor: "white",
      borderColor: "#EBEBED",
      borderRadius: 12,
      
    }),
    option: (styles: any, { data, isDisabled, isFocused, isSelected }: any) => {
      //  const color = chroma(data.color);
      return {
        ...styles,

        // cursor: isDisabled ? 'not-allowed' : 'default',
      };
    },
  };
  return (
    <CreatableSelect
      className="selectList"
      styles={colourStyles}
      //  classNamePrefix="select"
      placeholder={placeholder}
      components={{
        IndicatorSeparator: () => null
      }}
      defaultValue={selectedValue}
      // isDisabled={isDisabled}
      // isLoading={isLoading}
      isClearable={true}
      // isRtl={isRtl}
      //isSearchable={isSearchable}
      name={placeholder}
      options={data}
    />
    // <FormControl variant="outlined" className="selectList">
    //   <InputLabel>{placeholder}</InputLabel>
    //   <Select
    //     variant="outlined"
    //     fullWidth
    //     value={selectedValue}
    //     onChange={(event) => {
    //       onChangeValue(event.target.value);
    //     }}

    //   >
    //     {data.map((item: any, i: number) => {
    //       return (
    //         <MenuItem value={i}>
    //           {item?.text}

    //         </MenuItem>
    //       );
    //     })}
    //   </Select>
    // </FormControl>
  );
};

export default SelectList;

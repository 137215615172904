import { GetSlidersModel } from "Models";
import { call, put, takeLatest, select } from "redux-saga/effects";
import { getSliders } from "Services/Queries";
import languageId from "Utils/LanguageId";
import { slidersRequest, slidersRequestSuccess } from "./index";
import { globalStore } from "Store/global";

function* handleSlidersRequest() {
  try {
    const { languageId } = yield select(globalStore);
    const getSlidersResults: GetSlidersModel = yield call(() =>
      getSliders({ languageId: languageId })
    );
    yield put(slidersRequestSuccess(getSlidersResults.WebSliderList));
  } catch (error) {}
}

function* slidersSaga() {
  yield takeLatest(slidersRequest, handleSlidersRequest);
}

export default slidersSaga;

import { FunctionComponent } from "react";
import "./AnnouncementCard.scss";
import { ChevronDarkRightIcon, Notification } from "Icons";
import { Box } from "@material-ui/core";
import { useWindowDimensions } from "Hooks";

type CardProps = {
  title: string | undefined;
  subtitle: string | undefined;
  id: number | undefined;
  onClick: () => void | undefined;
  itemID?: string | undefined;
};

const AnnouncementCard: FunctionComponent<CardProps> = ({
  title,
  subtitle,
  onClick,
  itemID,
  id,
  ...props
}) => {
  const { width } = useWindowDimensions();
  return (
    <div
      className="announcementCard__item"
      onClick={onClick}
      itemID={itemID}
      style={{
        width: width < 500 ? width * 0.95 : 482,
        marginLeft: id === 0 ? 35 : 0,
      }}
    >
      <Notification />
      <Box className="announcementCard__item__text">
        <h5 className="announcementCard__item__title">{title}</h5>
        <p className="announcementCard__item__date">{subtitle}</p>
      </Box>
      <ChevronDarkRightIcon />
    </div>
  );
};

export default AnnouncementCard;

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { GetApplicationcenterModel } from "Models";
import { RootState } from "../index";
import { ApplicationcenterState } from "./types";

export const initialState: ApplicationcenterState = {
  loading: true,
  ApplicationCenterList: [],
};

export const applicationCenterSlice = createSlice({
  name: "applicationCenter",
  initialState,
  reducers: {
    onInit: (state) => {},
    applicationcenterRequest: (state: ApplicationcenterState) => {
      state.loading = true;
    },
    applicationcenterRequestSuccess: (
      state: ApplicationcenterState,
      action: PayloadAction<GetApplicationcenterModel>
    ) => {
      state.ApplicationCenterList = action.payload.ApplicationCenterList;
      state.loading = false;
    },
  },
});

export const { onInit, applicationcenterRequest, applicationcenterRequestSuccess } = applicationCenterSlice.actions;

export const ApplicationcenterStore = (state: RootState) => state.applicationCenter;

export default applicationCenterSlice.reducer;

// @ts-nocheck
import { getIstanbulkartFindContentParams } from "Config";
import { axiosHelper } from "../AxiosHelper";
import { GetIstanbulkartFindContentModel } from "../../Models";

const url = `${process.env.REACT_APP_UMBRACO_URL}/Search/IstanbulkartFindContent`;
const getIstanbulkartFindContent = async ({
  payload,
}: getIstanbulkartFindContentParams): Promise<GetIstanbulkartFindContentModel> => {
  return (
    await axiosHelper({
      method: "post",
      url,
      data: payload,
    })
  ).data;
};

export default getIstanbulkartFindContent;

import Cookies, { CookieSetOptions } from "universal-cookie";

export const cookies = new Cookies();
export const API_GW_TOKEN_TIMEOUT = 1800; // Seconds
export const API_GW_REFRESH_TOKEN_TIMEOUT = API_GW_TOKEN_TIMEOUT * 2; // Seconds

type cookieKeys = "apiGWToken" | "apiGWRefreshToken";

type cookieKeyType = {
  name: string;
  options?: CookieSetOptions;
};

export const cookieKeys: { [key in cookieKeys]: cookieKeyType } = {
  apiGWToken: {
    name: "apiGWToken",
    options: {
      maxAge: API_GW_TOKEN_TIMEOUT,
      secure: process.env.NODE_ENV === "production",
    },
  },
  apiGWRefreshToken: {
    name: "apiGWRefreshToken",
    options: {
      maxAge: API_GW_REFRESH_TOKEN_TIMEOUT,
      secure: process.env.NODE_ENV === "production",
    },
  },
};

// @ts-nocheck
import { getCardsParams } from "Config";
import { axiosHelper } from "../AxiosHelper";
import { GetCardsModel } from "../../Models";
const url = `${process.env.REACT_APP_UMBRACO_URL}/Card/GetCards`;
const getCards = async ({
  languageId,
}: getCardsParams): Promise<GetCardsModel> => {
  return (
    await axiosHelper({
      method: "get",
      url,
      data: { LanguageId: languageId },
      parameters: { languageId },
    })
  ).data;
};

export default getCards;

import { GetIstanbulkartFindContentModel } from "Models";
import { call, put, select, takeLatest } from "redux-saga/effects";
import { getIstanbulkartLegalTextPageService } from "Services/Queries";
import { getIstanbulkartLegalTextPage, legalTextList } from "./index";
import LegalTextPageContentList from "Models/GetIstanbulkartLegalTextPageModel";
import { globalStore } from "Store/global";

function* getIstanbulkartLegalTextPageRequest() {
  try {
    const { languageId } = yield select(globalStore);

    const getIstanbulkartFindContentResult: LegalTextPageContentList =
      yield call(() => getIstanbulkartLegalTextPageService(languageId));
    yield put(
      legalTextList(getIstanbulkartFindContentResult?.LegalTextPageContentList)
    );
  } catch (error) {}
}

function* istanbulkartFindContentSaga() {
  yield takeLatest(
    getIstanbulkartLegalTextPage,
    getIstanbulkartLegalTextPageRequest
  );
}

export default istanbulkartFindContentSaga;

import { all, spawn } from "redux-saga/effects";
//Sagas
import globalSaga from "./global/sagas";
import faqsSaga from "./getFaqs/sagas";
import securitySaga from "./getSecurity/sagas";
import newsSaga from "./getNews/sagas";
import campaignSaga from "./getCampaigns/sagas";
import announcementsSaga from "./getAnnouncements/sagas";
import slidersSaga from "./getSliders/sagas";
import checkCustomerInfromationSaga from "./checkCustomerInformationDemo/sagas";
import blogsSaga from "./getBlog/sagas";
import cardsSaga from "./getCards/sagas";
import priceLimitSaga from "./GetPriceLimits/sagas";
import applicationcenterSaga from "./GetApplicationcenter/sagas";
import istanbulkartFindContentSaga from "./getIstanbulkartFindContent/sagas";
import GetIstanbulkartLegalTextPage from "./getIstanbulkartLegalTextPage/sagas";
import faqDetailSaga from "./getFaqsDetail/sagas";

export default function* rootSaga() {
  yield all([
    spawn(globalSaga),
    spawn(faqsSaga),
    spawn(faqDetailSaga),
    spawn(securitySaga),
    spawn(newsSaga),
    spawn(campaignSaga),
    spawn(announcementsSaga),
    spawn(slidersSaga),
    spawn(checkCustomerInfromationSaga),
    spawn(blogsSaga),
    spawn(cardsSaga),
    spawn(priceLimitSaga),
    spawn(applicationcenterSaga),
    spawn(istanbulkartFindContentSaga),
    spawn(GetIstanbulkartLegalTextPage),
  ]);
}

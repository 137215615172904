import { routePath } from "Navigator/routes";
import { FunctionComponent } from "react";
import "./NewBox.scss";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";

type NewBoxProps = {
  title?: string;
  date?: string;
  image?: string;
  desc?: string;
  Id?: number | string;
  slug?: string;
  readingTime?: string;
};

const NewBox: FunctionComponent<NewBoxProps> = ({
  slug,
  title,
  date,
  image,
  desc,
  Id,
  readingTime,
}) => {
  const navigate = useNavigate();
  const descfilter = desc?.replace(/(<([^>]+)>)/gi, "");
  return (
    <Link
      to={`${routePath.newsDetail.replace(":slug", Id as string)}`}
      className="news__box"
    >
      <div className="news__box__image">
        <img src={image} alt={title} />
      </div>
      <div className="news__box__content">
        <span>{date}</span>
        <h3>{title}</h3>
        <p>{descfilter}</p>
      </div>
    </Link>
  );
};

export default NewBox;

import { connectRouter } from "connected-react-router";
import { combineReducers } from "redux";
import { History } from "history";
import global from "./global/slices";
import faqs from "./getFaqs/slices";
import security from "./getSecurity/slices";
import news from "./getNews/slices";
import campaigns from "./getCampaigns/slices";
import announcements from "./getAnnouncements/slices";
import sliders from "./getSliders/slices";
import customerInformation from "./checkCustomerInformationDemo/slices";
import blogs from "./getBlog/slices";
import cards from "./getCards/slices";
import priceLimits from "./GetPriceLimits/slices";
import applicationCenter from "./GetApplicationcenter/slices";
import istanbulkartFindContent from "./getIstanbulkartFindContent/slices";
import getIstanbulkartLegalTextPage from "./getIstanbulkartLegalTextPage/slices";
import faqsDetail from "./getFaqsDetail/slices";

// Add Reducers Here
const rootReducer = (history: History) =>
  combineReducers({
    router: connectRouter(history),
    global,
    faqs,
    faqsDetail,
    security,
    news,
    campaigns,
    announcements,
    sliders,
    customerInformation,
    blogs,
    cards,
    priceLimits,
    applicationCenter,
    istanbulkartFindContent,
    getIstanbulkartLegalTextPage,
  });

export default rootReducer;

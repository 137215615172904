import { GetNewsModel } from "Models";
import { call, put, takeLatest, select } from "redux-saga/effects";
import { getNews } from "Services/Queries";
import { newsRequest, newsRequestSuccess } from "./index";
import { globalStore } from "Store/global";
import slugify from "slugify";

function* handleFaqsRequest() {
  try {
    const { languageId } = yield select(globalStore);
    const getNewsResult: GetNewsModel = yield call(() =>
      getNews({ languageId: languageId })
    );
    const news = getNewsResult.NewsList.map((val) => ({
      ...val,
      Slug: slugify(val.HeadLine as string, {
        lower: true,
        trim: true,
        locale: "en",
        strict: true,
      }),
    })) as GetNewsModel["NewsList"];
    yield put(newsRequestSuccess(news));
  } catch (error) {}
}

function* newsSaga() {
  yield takeLatest(newsRequest, handleFaqsRequest);
}

export default newsSaga;

import { FC } from "react";
import {
  TextFieldProps as MaterialUITextFieldProps,
  TextField as MaterialUITextField,
  IconButton,
} from "@material-ui/core";
import "./SearchField.scss";
import { Search, CloseFilled } from "Icons";
import { useLocalization } from "Hooks";

type TextFieldProps = {
  text?: string;
  onSearch: (searchTerm: string) => void;
  isDisabled?: boolean;
  light?: boolean;
  marginTop?: number | string;
  maxWidth?: string;
  backgroundColor?: string;
  setSearchQuery: Function;
  searchQuery: any;
  placeHolder?: string;
  keyValue?: any;
} & MaterialUITextFieldProps;

const SearchField: FC<TextFieldProps> = ({
  children,
  text,
  setSearchQuery,
  searchQuery,
  onSearch,
  placeHolder,
  keyValue,
  ...props
}) => {
  const localization = useLocalization();
  const handleButtonClick = () => {
    if (searchQuery.length > 0) {
      setSearchQuery("");
    } else {
      onSearch(searchQuery);
    }
  };

  return (
    <form>
      <div  className="searchBar">
        <MaterialUITextField
            id="search-bar"
            className="searchField"
            onInput={(e: any) => {
              setSearchQuery(e?.target?.value);
            }}
            value={searchQuery}
            label={placeHolder ?? ""}
            variant="filled"
            placeholder={localization?.application_center_search_desc}
            size="medium"
        />
        <IconButton
            aria-label="toggle password visibility"
            onClick={handleButtonClick}
            edge="end"
            style={{ marginLeft: -50, marginTop: 5 }}
        >
          {searchQuery.length > 0 ? (
              <div>
                <CloseFilled />
              </div>
          ) : (
              <Search id={keyValue} />
          )}
        </IconButton>
      </div>

    </form>
  );
};

export default SearchField;

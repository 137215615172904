import * as React from "react";

function Linkedin(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1308_18340)">
        <path
          d="M16 28C9.3736 28 4 22.6264 4 16C4 9.3736 9.3736 4 16 4C22.6264 4 28 9.3736 28 16C28 22.6264 22.6264 28 16 28Z"
          fill="#1581BE"
        />
        <path
          d="M9.39481 13.7099H12.2248V22.8099H9.39481V13.7099ZM10.8148 9.18994C11.7248 9.18994 12.4548 9.91994 12.4548 10.8299C12.4548 11.7399 11.7248 12.4699 10.8148 12.4699C9.9048 12.4699 9.1748 11.7299 9.1748 10.8299C9.1748 9.92994 9.9048 9.18994 10.8148 9.18994Z"
          fill="white"
        />
        <path
          d="M14.0051 13.71H16.7151V14.95H16.7551C17.1351 14.23 18.0551 13.48 19.4351 13.48C22.2951 13.48 22.8251 15.36 22.8251 17.81V22.8H19.9951V18.38C19.9951 17.32 19.9751 15.97 18.5251 15.97C17.0751 15.97 16.8251 17.12 16.8251 18.31V22.81H13.9951V13.71H14.0051Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_1308_18340">
          <rect
            width="24"
            height="24"
            fill="white"
            transform="translate(4 4)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

export default Linkedin;

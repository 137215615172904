import { FunctionComponent } from "react";

const Notification: FunctionComponent = (
  props: React.SVGProps<SVGSVGElement>
) => {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_1328_1042"
        maskUnits="userSpaceOnUse"
        x="3"
        y="2"
        width="18"
        height="21"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M18.7201 13.4183L20.5201 15.2283C20.9824 15.6983 21.1183 16.3993 20.8652 17.0079C20.6122 17.6166 20.0193 18.0146 19.3601 18.0183H16.0001V18.3583C15.9029 20.472 14.1141 22.1087 12.0001 22.0183C9.88614 22.1087 8.09737 20.472 8.00012 18.3583V18.0183H4.64012C3.98095 18.0146 3.38806 17.6166 3.13499 17.0079C2.88193 16.3993 3.01788 15.6983 3.48012 15.2283L5.28012 13.4183V8.74831C5.28366 6.80947 6.12196 4.96607 7.58084 3.68907C9.03973 2.41206 10.9779 1.82516 12.9001 2.07831C16.2856 2.59719 18.7696 5.53365 18.7201 8.95831V13.4183ZM12.0001 20.0183C13.0035 20.0891 13.8849 19.3576 14.0001 18.3583V18.0183H10.0001V18.3583C10.1153 19.3576 10.9967 20.0891 12.0001 20.0183Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_1328_1042)">
        <rect y="0.0183105" width="24" height="24" fill="#1AAADC" />
      </g>
    </svg>
  );
};

export default Notification;

// @ts-nocheck
import { getCampaignsParams } from "Config";
import { axiosHelper } from "../AxiosHelper";
import { GetCampaignsModel } from "./../../Models";

const url = `${process.env.REACT_APP_UMBRACO_URL}/Campaign/GetIstanbulkartCampaigns`;
const getCampaigns = async ({
  languageId,
}: getCampaignsParams): Promise<GetCampaignsModel> => {
  return (
    await axiosHelper({
      method: "post",
      url,
      data: { languageId: languageId },
      parameters: { languageId },
    })
  ).data;
};

export default getCampaigns;

import { FunctionComponent, useState } from "react";
import "./Input.scss";
import { ErrorMessage } from "Components";
import { QuestionMark } from "Icons";
import Images from "Assets/Images";
import { useLocalization } from "Hooks";
export interface IInputProps {
  labelText: string;
  typeInput?: string;
  name?: string;
  text?: string;
  placeholder?: string;
  onClick?: Function;
  backgroundColor?: string;
  value: string;
  onChangeText: Function;
  maxLength?: number;
  style?: object;
  isQuestionMark?: boolean;
  width?: number | string;
  errorMessage?: string | undefined | boolean;
  handleFocus?: Function;
  isTooltip?: boolean;
  onClickTooltip?: Function;
  onMouseOverTooltip?: Function;
  onMouseLeaveTooltip?: Function;
  openPopover?: boolean;
  marginLeft?: string | number;
  marginRight?: string | number;
  onKeyDown?: Function;
  height?: number | string;
  RightIcon?: any;
  onPressRightIcon?: Function;
  suffix?: string;
  className?: string;
  handleBlur?: Function;
}
const Input: FunctionComponent<IInputProps> = ({
  labelText,
  text,
  name,
  backgroundColor,
  onClick,
  typeInput = "text",
  value,
  onChangeText,
  maxLength,
  style,
  isQuestionMark,
  width,
  errorMessage,
  isTooltip,
  onClickTooltip = () => {},
  onMouseOverTooltip = () => {},
  onMouseLeaveTooltip = () => {},
  openPopover,
  marginLeft,
  marginRight,
  onKeyDown = () => {},
  height,
  RightIcon,
  onPressRightIcon = () => {},
  suffix,
  handleBlur,
  handleFocus,
  placeholder,
  className,
  ...props
}) => {
  const [_isFocused, setIsFocused] = useState(false);
  const localization = useLocalization();
  const _handleFocus = (e: any) => {
    if (!_isFocused) {
      setIsFocused(true);
      handleFocus && handleFocus(e);
    }
  };

  const _handleBlur = (e: any) => {
    if (_isFocused) {
      setIsFocused(false);
      handleBlur && handleBlur(e);
    }
  };
  return (
    <div className="input-area">
      <div
        className={`input ${className || ""}`}
        style={{
          width: width,
          height: height,
          // marginLeft: marginLeft ? marginLeft : "25%",
          // marginRight: marginRight ? marginRight : "25%",
        }}
      >
        <label className="input__label">{labelText}</label>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          {suffix && <span className="input__prefix">{suffix}</span>}
          <input
            className="input__inputField"
            name={name}
            type={typeInput}
            value={value}
            placeholder={placeholder}
            onChange={(event) => {
              onChangeText(event.target.value);
            }}
            max={maxLength}
            style={style}
            onFocus={(e) => _handleFocus(e)}
            onBlur={(e) => {
              _handleBlur(e);
            }}
            {...props}
          />
        </div>

        {RightIcon && (
          <div
            className="input__close"
            onClick={() => {
              onPressRightIcon();
            }}
          >
            {RightIcon}
          </div>
        )}

        {isTooltip && (
          <div
            className="input__toolTip"
            onClick={() => {
              onClickTooltip();
            }}
            onMouseOver={() => {
              onMouseOverTooltip();
            }}
            onMouseLeave={() => {
              onMouseLeaveTooltip();
            }}
          >
            <QuestionMark fill={"#383C52"} />
          </div>
        )}
      </div>

      {errorMessage ? (
        <ErrorMessage
          textStyle={{
            color: "#FF5757",
            fontFamily: "Mark Pro",
            marginBottom: 4,
            marginTop: 4,
            fontSize: 11,
          }}
          errorText={errorMessage}
        />
      ) : null}
    </div>
  );
};

export default Input;

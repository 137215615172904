import { FunctionComponent } from "react";

const RightArrow: FunctionComponent<React.SVGProps<SVGSVGElement>> = ({
  fill,
}: any) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_878_1193"
        maskUnits="userSpaceOnUse"
        x="8"
        y="5"
        width="8"
        height="14"
      >
        <path
          d="M8.29289 5.29289C8.65338 4.93241 9.22061 4.90468 9.6129 5.2097L9.70711 5.29289L15.7071 11.2929C16.0676 11.6534 16.0953 12.2206 15.7903 12.6129L15.7071 12.7071L9.70711 18.7071C9.31658 19.0976 8.68342 19.0976 8.29289 18.7071C7.93241 18.3466 7.90468 17.7794 8.2097 17.3871L8.29289 17.2929L13.585 12L8.29289 6.70711C7.93241 6.34662 7.90468 5.77939 8.2097 5.3871L8.29289 5.29289Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_878_1193)">
        <rect width="24" height="24" fill={fill ?? "#1AAADC"} />
      </g>
    </svg>
  );
};

export default RightArrow;

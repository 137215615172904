import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { GetCampaignsModel } from "Models";
import { RootState } from "../index";
import { CampaignsState } from "./types";

export const initialState: CampaignsState = {
  loading: true,
  Campaigns: undefined,
};

export const campaignsSlice = createSlice({
  name: "campaigns",
  initialState,
  reducers: {
    onInit: (state) => {},
    campaignsRequest: (state: CampaignsState) => {
      state.loading = true;
    },
    campaignsRequestSuccess: (
      state: CampaignsState,
      action: PayloadAction<GetCampaignsModel["Campaigns"]>
    ) => {
      state.Campaigns = action.payload;
      state.loading = false;
    },
  },
});

export const { onInit, campaignsRequest, campaignsRequestSuccess } =
  campaignsSlice.actions;

export const campaignsStore = (state: RootState) => state.campaigns;
//export const storeCampaignsData = (state: RootState) => state.campaigns.Campaigns;

export default campaignsSlice.reducer;

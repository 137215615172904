import { ThemeProvider } from "@material-ui/core";
import { useEffect } from "react";
import { cmsRequest } from "./Store/global";
import { faqsRequest } from "Store/getFaqs";
import { newsRequest } from "Store/getNews";
import { campaignsRequest } from "Store/getCampaigns";
import { announcementsRequest } from "Store/getAnnouncements";
import { slidersRequest } from "Store/getSliders";
import { blogsRequest } from "Store/getBlog";
import { cardsRequest } from "Store/getCards";
import { globalStore } from "Store/global";
import { priceLimitRequest } from "Store/GetPriceLimits";
import { applicationcenterRequest } from "Store/GetApplicationcenter";
import { useDispatch, useSelector } from "react-redux";

import Navigator from "./Navigator";
import { materialUITheme } from "./Theme/MaterialUITheme";
import { useLocalization } from "Hooks";
import { Footer, Header, Loading } from "Components";

function App() {
  const dispatch = useDispatch();
  const { languageId } = useSelector(globalStore);
  const localization = useLocalization();

  useEffect(() => {
    if (location.pathname === "/") {
      setTimeout(() => {
        dispatch(cardsRequest());
        dispatch(priceLimitRequest());
        dispatch(faqsRequest(""));
        dispatch(applicationcenterRequest());
        dispatch(newsRequest());
        dispatch(blogsRequest({ LanguageId: languageId, CategoryName: "" }));
      }, 3000);
    } else {
      dispatch(cardsRequest());
      dispatch(priceLimitRequest());
      dispatch(faqsRequest(""));
      dispatch(applicationcenterRequest());
      dispatch(newsRequest());
      dispatch(blogsRequest({ LanguageId: languageId, CategoryName: "" }));
    }
    dispatch(cmsRequest());
    dispatch(campaignsRequest());
    dispatch(announcementsRequest());
    dispatch(slidersRequest());
  }, []);

  return (
    <ThemeProvider theme={materialUITheme}>
      {!localization ? (
        <Loading />
      ) : (
        <>
          <Header />
          <Navigator />
          <Footer />
        </>
      )}
    </ThemeProvider>
  );
}

export default App;

import { FunctionComponent, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Close, RightArrow } from "../../Icons";
import { SelectList, Button } from "Components";
import { useNavigate } from "react-router-dom";
import { ModalProps, Modal } from "@material-ui/core";
import "./Dialog.scss";
import { useWindowDimensions } from "Hooks";
import { routePath } from "Navigator/routes";
import Images from "Assets/Images";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    position: "absolute",
    width: 550,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    borderRadius: 12,
    height: 564,
  },
  paperTiny: {
    position: "absolute",
    width: 300,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    borderRadius: 12,
    height: 491,
  },
  paperTiny2: {
    position: "absolute",
    width: 300,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    borderRadius: 12,
    height: 399,
  },
  paper2: {
    position: "absolute",
    width: 550,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(1, 4, 10),
    borderRadius: 12,
    height: 467,
  },
  title: {
    fontFamily: "Mark Pro",
    fontWeight: 700,
    fontSize: 24,
    textAlign: "center",
    letterSpacing: -0.6,
    color: "#383C52",
    marginTop: 50,
  },
  content: {
    fontFamily: "Mark Pro",
    fontWeight: 400,
    fontSize: 16,
    textAlign: "center",
    letterSpacing: -0.4,
    color: "#134A63",
  },
  container: {
    display: "flex",
    flexDirection: "column",
    marginTop: "5%",
  },
  close: {
    position: "absolute",
    right: 24,
    top: 24,
  },
}));

type DialogProps = {
  open?: boolean;
  handleClose?: Function;
} & ModalProps;

const Dialog: FunctionComponent<DialogProps> = ({
  open,
  handleClose = () => {},
  children,
  ...props
}) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { width } = useWindowDimensions();
  const [modalStyle, setModalStyle] = useState(getModalStyle);
  const [cinsiyet, setCinsiyet] = useState("");
  const [yas, setYas] = useState("");
  const [meslek, setMeslek] = useState("");
  const [ozelDurum, setOzelDurum] = useState("");
  const [displayCards, setDisplayCards] = useState(false);
  const title = displayCards
    ? "Senin için uygun kartlar*"
    : "Hangi kart sana uygun?*";
  function getModalStyle() {
    return {
      top: "15%",
      left: width <= 400 ? "5%" : width < 800 ? "12%" : "28%",
    };
  }
  useEffect(() => {
    setModalStyle(getModalStyle);
  }, [width]);
  return (
    <div>
      <Modal
        open={open}
        // onClose={()=>{handleClose}}
      >
        <div
          style={modalStyle}
          className={
            width < 900 && displayCards
              ? classes.paperTiny2
              : width < 900
              ? classes.paperTiny
              : displayCards
              ? classes.paper2
              : classes.paper
          }
        >
          <div
            className={classes.close}
            onClick={() => {
              setDisplayCards(false);
              handleClose();
            }}
          >
            <Close />
          </div>
          <h2 className={classes.title}>{title}</h2>
          <p className={classes.content}>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi
            accumsan odio enim, non pharetra est ultrices et.*
          </p>
          <div className={classes.container}>
            {!displayCards ? (
              <>
                <SelectList
                  selectedValue={cinsiyet}
                  data={[
                    { value: 1, label: "Kadın*" },
                    { value: 2, label: "Erkek*" },
                  ]}
                  placeholder={"Cinsiyetiniz*"}
                  onChangeValue={(val: any) => {
                    setCinsiyet(val);
                  }}
                />
                <div style={{ height: "3%" }} />
                <SelectList
                  selectedValue={yas}
                  data={[
                    { value: "1", label: "18" },
                    { value: "2", label: "19" },
                    { value: "3", label: "20" },
                  ]}
                  placeholder={"Yaş aralığınız*"}
                  onChangeValue={(val: any) => {
                    setYas(val);
                  }}
                />
                <div style={{ height: "1%" }} />
                <SelectList
                  selectedValue={meslek}
                  data={[
                    { value: "1", label: "Lorem*" },
                    { value: "2", label: "Lorem*" },
                    { value: "3", label: "Lorem*" },
                  ]}
                  placeholder={"Mesleğiniz*"}
                  onChangeValue={(val: any) => {
                    setMeslek(val);
                  }}
                />
                <div style={{ height: "1%" }} />
                <SelectList
                  selectedValue={ozelDurum}
                  data={[
                    { value: "1", label: "Lorem*" },
                    { value: "2", label: "Loremm*" },
                    { value: "3", label: "Loremmm*" },
                  ]}
                  placeholder={"Özel Durumunuz*"}
                  onChangeValue={(val: any) => {
                    setOzelDurum(val);
                  }}
                />
                <div style={{ height: "1%" }} />
                <Button
                  text="Kartları görüntüle*"
                  onClick={() => {
                    setDisplayCards(true);
                  }}
                  variant="contained"
                  className="dialog__button"
                />
              </>
            ) : (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <div
                  className="dialog__row"
                  onClick={() => {
                    navigate(routePath.campaignsDetail);
                  }}
                >
                  <div className="dialog__row__rowText">
                    <img
                      src={Images.IstanbulkartYellow}
                      width={31}
                      height={48}
                      alt="Istanbulkart"
                    />
                    <div style={{ marginLeft: 24 }}>
                      <p className="dialog__row__title">Ücretsiz Kart*</p>
                      <p className="dialog__row__content">Anne Kart*</p>
                    </div>
                  </div>
                  <RightArrow fill={"#383C52"} />
                </div>
                <div
                  className="dialog__row"
                  onClick={() => {
                    navigate(routePath.cardDetail);
                  }}
                >
                  <div className="dialog__row__rowText">
                    <img
                      src={Images.IstanbulkartYellow}
                      width={31}
                      height={48}
                      alt="Istanbulkart"
                    />
                    <div style={{ marginLeft: 24 }}>
                      <p className="dialog__row__title">Anonim Kart*</p>
                      <p className="dialog__row__content">Kırmızı Kart*</p>
                    </div>
                  </div>

                  <RightArrow fill={"#383C52"} />
                </div>
              </div>
            )}
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default Dialog;

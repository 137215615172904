import { FunctionComponent } from "react";

const InfoRed: FunctionComponent = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 2.66663C8.63616 2.66663 2.66663 8.63616 2.66663 16C2.66663 23.3638 8.63616 29.3333 16 29.3333C23.3638 29.3333 29.3333 23.3638 29.3333 16C29.3333 12.4637 27.9285 9.07235 25.4281 6.57187C22.9276 4.07138 19.5362 2.66663 16 2.66663ZM16 22.6666C15.2636 22.6666 14.6666 22.0697 14.6666 21.3333C14.6666 20.5969 15.2636 20 16 20C16.7363 20 17.3333 20.5969 17.3333 21.3333C17.3333 22.0697 16.7363 22.6666 16 22.6666ZM16 18.6666C16.7363 18.6666 17.3333 18.0697 17.3333 17.3333V10.6666C17.3333 9.93025 16.7363 9.33329 16 9.33329C15.2636 9.33329 14.6666 9.93025 14.6666 10.6666V17.3333C14.6666 18.0697 15.2636 18.6666 16 18.6666Z"
        fill="black"
      />
      <mask
        id="mask0_1_8526"
        maskUnits="userSpaceOnUse"
        x="2"
        y="2"
        width="28"
        height="28"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16 2.66663C8.63616 2.66663 2.66663 8.63616 2.66663 16C2.66663 23.3638 8.63616 29.3333 16 29.3333C23.3638 29.3333 29.3333 23.3638 29.3333 16C29.3333 12.4637 27.9285 9.07235 25.4281 6.57187C22.9276 4.07138 19.5362 2.66663 16 2.66663ZM16 22.6666C15.2636 22.6666 14.6666 22.0697 14.6666 21.3333C14.6666 20.5969 15.2636 20 16 20C16.7363 20 17.3333 20.5969 17.3333 21.3333C17.3333 22.0697 16.7363 22.6666 16 22.6666ZM16 18.6666C16.7363 18.6666 17.3333 18.0697 17.3333 17.3333V10.6666C17.3333 9.93025 16.7363 9.33329 16 9.33329C15.2636 9.33329 14.6666 9.93025 14.6666 10.6666V17.3333C14.6666 18.0697 15.2636 18.6666 16 18.6666Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_1_8526)">
        <rect width="32" height="32" fill="#FF5959" />
      </g>
    </svg>
  );
};

export default InfoRed;

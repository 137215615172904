import { FunctionComponent } from "react";

const Question: FunctionComponent = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_237_7610"
        maskUnits="userSpaceOnUse"
        x="2"
        y="2"
        width="28"
        height="28"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16.0001 2.66675C8.63628 2.66675 2.66675 8.63628 2.66675 16.0001C2.66675 23.3639 8.63628 29.3334 16.0001 29.3334C23.3639 29.3334 29.3334 23.3639 29.3334 16.0001C29.3334 12.4639 27.9287 9.07248 25.4282 6.57199C22.9277 4.07151 19.5363 2.66675 16.0001 2.66675ZM16.0001 24.0001C15.2637 24.0001 14.6667 23.4031 14.6667 22.6667C14.6667 21.9304 15.2637 21.3334 16.0001 21.3334C16.7365 21.3334 17.3334 21.9304 17.3334 22.6667C17.3334 23.4031 16.7365 24.0001 16.0001 24.0001ZM17.3334 18.6667V17.1201C19.6386 16.4328 21.0436 14.106 20.5787 11.7459C20.1138 9.38588 17.9311 7.76587 15.5375 8.00427C13.144 8.24266 11.3237 10.2614 11.3334 12.6667C11.3334 13.4031 11.9304 14.0001 12.6667 14.0001C13.4031 14.0001 14.0001 13.4031 14.0001 12.6667C14.0001 11.5622 14.8955 10.6667 16.0001 10.6667C17.1047 10.6667 18.0001 11.5622 18.0001 12.6667C18.0001 13.7713 17.1047 14.6667 16.0001 14.6667C15.2637 14.6667 14.6667 15.2637 14.6667 16.0001V18.6667C14.6667 19.4031 15.2637 20.0001 16.0001 20.0001C16.7365 20.0001 17.3334 19.4031 17.3334 18.6667Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_237_7610)">
        <rect width="32" height="32" fill="#383C52" />
      </g>
    </svg>
  );
};

export default Question;

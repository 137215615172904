// @ts-nocheck
import { getApplicationCenterParams } from "Config";
import { axiosHelper } from "../AxiosHelper";
import { GetApplicationcenterModel } from "Models";

const url = `${process.env.REACT_APP_UMBRACO_URL}/Applicationcenter/GetApplicationcenter`;

const getApplicationcenter = async ({
  languageId,
}: getApplicationCenterParams): Promise<GetApplicationcenterModel> => {
  return (
    await axiosHelper({
      method: "get",
      url,
      data: { LanguageId: languageId },
      parameters: { languageId },
    })
  ).data;
};

export default getApplicationcenter;

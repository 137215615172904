import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { GetFaqsModel } from "Models";
import { RootState } from "../index";
import { FaqsState } from "./types";

export const initialState: FaqsState = {
  loading: true,
  FaqList: [],
  FaqCategory: [],
};

export const faqsSlice = createSlice({
  name: "faqs",
  initialState,
  reducers: {
    onInit: (state) => {},
    faqsRequest: (state: FaqsState, action: PayloadAction<string>) => {
      state.loading = true;
    },
    faqsRequestSuccess: (
      state: FaqsState,
      action: PayloadAction<GetFaqsModel>
    ) => {
      state.FaqList = action.payload.Faqs;
      state.FaqCategory = action.payload.FaqCategory;
      state.loading = false;
    },
  },
});

export const { onInit, faqsRequest, faqsRequestSuccess } = faqsSlice.actions;

export const faqsStore = (state: RootState) => state.faqs;
export const storeFaqsData = (state: RootState) => state.faqs.FaqList;

export default faqsSlice.reducer;

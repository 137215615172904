// @ts-nocheck
import { getCheckParams } from "Config";
import { axiosHelper } from "../AxiosHelper";

const checkCustomerInformation = async ({
  Name,
  Surname,
  Birthday,
  CitizenshipNumber,
  CardNumber,
  PhoneNumber,
  KVKKAgreementVersion,
  Mail,
  CommercialMessageStatus,
  PhoneCountryCode,
  SMS,
  EMAIL,
  ExcplictConsent,
  token,
}: getCheckParams): Promise<CheckCustomerInformationDemoModel.request> => {
  return (
    await axiosHelper({
      method: "post",
      headers: {
        "Content-Type": "application/json; charset=UTF-8",
      },
      gatewayData: {
        command: "ksl.CheckCustomerInformationDemo",
        token,
      },
      data: {
        Name,
        Surname,
        Birthday,
        CitizenshipNumber,
        CardNumber: CardNumber.replaceAll(" ", ""),
        PhoneNumber,
        KVKKAgreementVersion,
        Mail,
        CommercialMessageStatus,
        PhoneCountryCode,
        SMS,
        EMAIL,
        ExcplictConsent,
      },
    })
  ).data;
};

export default checkCustomerInformation;

import { FunctionComponent } from "react";

const BigRightArrow: FunctionComponent = (
  props: React.SVGProps<SVGSVGElement>
) => {
  return (
    <svg
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_265_8161"
        maskUnits="userSpaceOnUse"
        x="12"
        y="7"
        width="12"
        height="22"
      >
        <path
          d="M14.5607 28.0607C13.9749 28.6464 13.0251 28.6464 12.4393 28.0607C11.8986 27.5199 11.857 26.6691 12.3146 26.0807L12.4393 25.9393L20.3775 18L12.4393 10.0607C11.8986 9.51993 11.857 8.66909 12.3146 8.08065L12.4393 7.93934C12.9801 7.39862 13.8309 7.35702 14.4193 7.81456L14.5607 7.93934L23.5607 16.9393C24.1014 17.4801 24.143 18.3309 23.6854 18.9193L23.5607 19.0607L14.5607 28.0607Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_265_8161)">
        <rect
          x="36"
          y="36"
          width="36"
          height="36"
          transform="rotate(180 36 36)"
          fill="#1581BE"
        />
      </g>
    </svg>
  );
};

export default BigRightArrow;

import { FunctionComponent, useState } from "react";
import {} from "@material-ui/core";
import "./Tab.scss";
import { Typography } from "@material-ui/core";
import classNames from "classnames";

type TabProps = {
  text1?: string;
  text2?: string;
  text3?: string;
  setSelectionText?: Function;
  //selectionText?: string;
};

const Tab: FunctionComponent<TabProps> = ({
  text1,
  text2,
  text3,
  setSelectionText=()=>{},
  ...props
}) => {
  const [section1, setSection1] = useState(true);
  const [section2, setSection2] = useState(false);
  const [section3, setSection3] = useState(false);

  return (
    <div className="tab">
      <div
        className={classNames({
          tab__selected__section: section1,
          tab__unselected__section: !section1,
        })}
        onClick={() => {
          setSection1(true);
          setSection2(false);
          setSection3(false);
          setSelectionText(text1);
        }}
      >
        <Typography variant="h5">{text1}</Typography>
      </div>
      <div
        className={classNames({
          tab__selected__section: section2,
          tab__unselected__section: !section2,
        })}
        onClick={() => {
          setSection1(false);
          setSection2(true);
          setSection3(false);
          setSelectionText(text2);
        }}
      >
        <Typography variant="h5">{text2}</Typography>
      </div>
      <div
        className={classNames({
          tab__selected__section: section3,
          tab__unselected__section: !section3,
        })}
        onClick={() => {
          setSection1(false);
          setSection2(false);
          setSection3(true);
          setSelectionText(text3);
        }}
      >
        <Typography variant="h5">{text3}</Typography>
      </div>
    </div>
  );
};

export default Tab;
